import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const bg = {
  backgroundImage: `url("/career1.jpg")`,
  backgroundSize: "cover",
  backgroundPosition: "right",

  backgroundRepeat: " no-repeat",
  borderBottom: "5px solid #d7df00",
};
const Careers = () => {
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="webportal main-home  " style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-8 md-pl-14 mt-140">
                <div className="sec-title3 mb-40" >
                  <h2
                    className="title  mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", color: "#ccffff" }}
                  >
                    Grow with us
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    At Esquare, we value your passion for technologies and
                    provide opportunities to build a world that is connected,
                    intellegent, cleaner and greener.
                    <hr style={{ border: "1px solid white" }} />
                    <span style={{ color: "#d7df00" }}>Share this page</span>
                    <div id="sociallinks" style={{ fontSize: "20px" }}>
                      {" "}
                      <i
                        style={{ marginRight: "5px" }}
                        className="fa fa-facebook"
                        id="yui_patched_v3_11_0_1_1672672313038_361"
                      ></i>{" "}
                      <i
                        style={{ marginRight: "5px" }}
                        className="fa fa-twitter"
                        id="yui_patched_v3_11_0_1_1672672313038_356"
                      ></i>{" "}
                      <i
                        style={{ marginRight: "5px" }}
                        className="fa fa-linkedin"
                        id="yui_patched_v3_11_0_1_1672672313038_354"
                      ></i>{" "}
                      <i className="fa fa-google-plus"></i>{" "}
                    </div>
                  </p>
                </div>
              </div>
              <div className=" col-lg-4 md-pl-14 mt-150">
                <img src="/top.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" col-lg-12 pt-30 pb-30 careers">
        <div className="container">
          <table>
            <tr style={{backgroundColor:"	#A9A9A9"}}>
              <th>Position</th>
              <th>Location</th>
              <th> Department</th>
              <th> Date</th>
            </tr>
            <tr>
              <td><Link to="">Java Developer</Link></td>
              <td> Bangalore</td>
              <td> Development</td>
              <td>019-01-02</td>
            </tr>
            <tr>
              <td><Link to="">Liferay Developer</Link></td>
              <td> Bangalore</td>
              <td> Development</td>
              <td>2019-01-02</td>
            </tr>
            <tr>
              <td><Link to=""> Marketing Executive</Link></td>
              <td> Bangalore</td>
              <td>Marketing</td>
              <td>2019-01-02</td>
            </tr>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Careers;
