import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/web1-1.jpg";
import img2 from "../../assets/img/serviceinsidepics/web1-2.jpg";
import img3 from "../../assets/img/serviceinsidepics/web1-3.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";
const Content = () => {
  const [document] =  usePrismicDocumentByUID("services_webportal","contentsid");

  const slice5 = document && document?.data;



  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/contentManagement.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/black1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.bgimg3.url:"/web3.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                     {slice5?slice5?.body[0].primary.tittle[0].text:"Content Management system"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:"Today managing content is very important because business                    activities are carried out on a vast scale and as a result,                    there are a lot of reports, data, and records being                    generated. Information is being created extensively in                    various formats and managing all such content efficiently is                    necessary to keep track of all that is being recorded. This                    is where using a Content Management System is necessary."}
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"Content Management System is a system that is used to manage                    website content. It has a host of features that include                   web-based publishing, revision control, content searching,                    content retrieving, indexing, and format management. Content                    may refer to binary files, images, audio, video, office                    documents, and Web content.                    A content management system (CMS) manages the creation                    and modification of digital content. It typically supports                    multiple users in a collaborative environment. ... A web                    content management system (WCM or WCMS) is a CMS designed to                    support the management of the content of Web pages."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#6B5B95" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Evaluation and Consulting"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"We can help you evaluate and select your next generation focused on your unique needs and goals. Supervise on the technical aspects and Custom development to provide user friendly interface"}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--40">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Implementation"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"We provide full service CMS implementation and website design and development using platform best-practices and mobile-first, responsive design."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--50"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Customization and Support"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"We have extensive experiencing customizing content management systems to meet your unique needs. After deployment, we can assist you with ongoing maintenance, patches, and technical support, including helping to manage your users and workflows."}
              />
            </div>
          </div>
        </div>
      </div>



       
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-340"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[2].primary.tittle[0].text:"Component CMS"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                ecommerce="ecommerce-none"
                subtitle1={slice5?slice5?.body[2].items[0].subtittle[0].text:"A component CMS is a software system which involves managing of components (usually customer content) at the component level instead of whole documents or web pages. Though it can work as a standalone application, most of the time it is a sub-functionality of all the other CMS systems (ECMS, WCMS, MCMS etc.). It can assist with marketing, monitoring costs, customer support, and training purposes."}
                subtitle2={slice5?slice5?.body[2].items[1].subtittle[0].text:"Component content management system. A component content management system (CCMS) is a content management system that manages content at a granular level (component) rather than at the document level."}
                subtitle3={slice5?slice5?.body[2].items[2].subtittle[0].text:" A content management system (CMS) is a software application or set of related programs that are used to create and manage digital content. CMSes are typically used for enterprise content management (ECM) and web content management (WCM)."}
              />
            </div>
          </div>
        </div>
      </div>
      


      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-200"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Mobile CMS (MCMS)"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice5?slice5?.body[3].primary.text1[0].text:"A mobile CMS is a software program that is customized to create and manage mobile web pages. It allows businesses to upload, edit, and approve content for their mobile website without any need for programming skills."}
                subtitle2={slice5?slice5?.body[3].primary.text2[0].text:"CMS’s Media & Broadcasting Services offers most advanced and cost-effective content delivery systems to our clients. Media & Broadcasting Solutions, a division of CMS Computers Ltd, is India’s leading and most trusted solution provider in traditional broadcast and online video."}
                title1={slice5?slice5?.body[3].primary.tittle2[0].text:"Media CMS"}

                subtitle3={slice5?slice5?.body[3].primary.text3[0].text:"This CMS is for media (radio and television)."}

              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Content;
