import React from "react";
import { Link, useLocation } from "react-router-dom";

const Menuitems = (props:any) => {
    const { parentMenu, secondParentMenu } = props;

    const location = useLocation();
    return ( <React.Fragment>
           <li className={parentMenu === 'course' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
            <Link to="/" >Home</Link></li>
        <li className={parentMenu === 'about' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
            <Link to="#">Technologies</Link>
            <ul className="sub-menu">
                
                <li>
                    <Link to="/Liferay">Liferay</Link>
                </li>
              
                <li>
                    <Link to="/Reacttech" className={location.pathname === "/about-2" ? "active-menu" : ""}>React</Link>
                </li>
                <li>
                    <Link to="/Angular" className={location.pathname === "/about-2" ? "active-menu" : ""}>Angular</Link>
                </li>
                <li>
                    <Link to="/Java" className={location.pathname === "/about-2" ? "active-menu" : ""}>Java</Link>
                </li>
                <li>
                    <Link to="/dotNet" className={location.pathname === "/about-2" ? "active-menu" : ""}>.Net</Link>
                </li>
                <li>
                    <Link to="/Springboot" className={location.pathname === "/about-2" ? "active-menu" : ""}>Spring Boot</Link>
                </li>
                <li>
                    <Link to="/Bigdata" className={location.pathname === "/about-2" ? "active-menu" : ""}>Big Data</Link>
                </li>
                <li>
                    <Link to="/Android" className={location.pathname === "/about-2" ? "active-menu" : ""}>Android</Link>
                </li>
            </ul>
        </li>
        <li className={parentMenu === 'course' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
            <Link to="#" >Solutions</Link>
            <ul className="sub-menu">
                <li>
                    <Link to="/Portal" className={location.pathname === "/course" ? "active-menu" : ""}>Portal</Link>
                </li>
                <li>
                    <Link to="/Cloud" className={location.pathname === "/course-2" ? "active-menu" : ""}>Cloud Computing</Link>
                </li>
                <li>
                    <Link to="/Content" className={location.pathname === "/course-3" ? "active-menu" : ""}>Content Management</Link>
                </li>
                <li>
                    <Link to="/Ecommerce" className={location.pathname === "/course-4" ? "active-menu" : ""}>E-Commerce</Link>
                </li>
                <li>
                    <Link to="/Management" className={location.pathname === "/course-5" ? "active-menu" : ""}>Business Management</Link>
                </li>
               
            </ul>
        </li>
          <li className={parentMenu === 'about' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
            <Link to="#">Services</Link>
            <ul className="sub-menu">
                <li>
                    <Link to="/WebPortal">Web Portal DevelopMent</Link>
                </li>
                <li>
                    <Link to="/WebDesign" >Web Designing</Link>
                </li>
                <li>
                    <Link to="/MobileApp" >Mobile App Development</Link>
                </li>
                <li>
                    <Link to="/CorparateTrain" >Corporate Training</Link>
                </li>
                <li>
                    <Link to="/WebHosting" >Web Hosting Services</Link>
                </li>
                <li>
                    <Link to="/OnlineMark" >Online Marketing</Link>
                </li>
                <li>
                    <Link to="/Business" >Business Consulting</Link>
                </li>
            </ul>
        </li>
        <li className={parentMenu === 'course' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
            <Link to="#" >ShowCase</Link>
            <ul className="sub-menu">
                <li>
                    <Link to="/CaseStudies" className={location.pathname === "/course" ? "active-menu" : ""}>Case Studies</Link>
                </li>
                <li>
                    <Link to="/Clientele" className={location.pathname === "/course-2" ? "active-menu" : ""}>Clientele</Link>
                </li>
                <li>
                    <Link to="/Brochure" className={location.pathname === "/course-3" ? "active-menu" : ""}>Brochure</Link>
                </li>
                <li> 
                    <Link to="/Presentation " className={location.pathname === "/course-4" ? "active-menu" : ""}>Presentation</Link>
                </li>
              
            </ul>
        </li>
        <li className={parentMenu === 'course' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
            <Link to="#" >About Us</Link>
            <ul className="sub-menu">
                <li>
                    <Link to="/Company" className={location.pathname === "/Company" ? "active-menu" : ""}>Company</Link>
                </li>
                <li>
                    <Link to="/Outsource" className={location.pathname === "/course-2" ? "active-menu" : ""}>OutSourcing Partners</Link>
                </li>
                <li>
                    <Link to="/MarketingPartner" className={location.pathname === "/course-3" ? "active-menu" : ""}>Marketing Partners</Link>
                </li>
                <li>
                    <Link to="/BecomePartner" className={location.pathname === "/course-4" ? "active-menu" : ""}>Become A Partner</Link>
                </li>
              
            </ul>
        </li>
      
    </React.Fragment> );
}
 
export default Menuitems;