import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";

import SectionTitle from "../../components/Common/SectionTitle";

// About Image
import AboutImage from "../../assets/img/about/home5/about-main.jpg";
import AboutImage1 from "../../assets/img/about/home5/about-main1.jpg";
import AboutImage2 from "../../assets/img/about/home5/about-main2.jpg";

const About = (props: any) => {
  const { ourservice } = props;

  useEffect(() => {
    AOS.init();
  });

  return (
    <div id="rs-about" className="rs-about style4 pt-30 pb-20 md-pt-3 md-pb-3">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-30 text-center"
          titleClass="title orange-color extra-bold mb-14 sm-mb-10"
          title={
            ourservice
              ? ourservice.body[0].primary.tittle[0].text
              : "OUR SERVICES"
          }
        />
        <div className="row justify-content-between">
          <div className="col-lg-6 md-mb-10">
            <div
              data-aos="zoom-in"
              data-aos-duration="1500"
              data-aos-delay="350"
            >
              <div className="img-part">
                <img
                  className="about-main"
                  src={
                    ourservice
                      ? ourservice.body[0].primary.serviceimg1.url
                      : AboutImage
                  }
                  alt="About Image"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content">
              {/* Section Title Start */}
              <SectionTitle
                titleClass="title mb-10 sm-mb-6"
                animateName="slide-right"
                animateDuration="1200"
                title={
                  ourservice
                    ? ourservice.body[0].primary.text1[0].text
                    : "Web Portal Development"
                }
                descClass="desc mb-26"
                description={
                  ourservice
                    ? ourservice.body[0].primary.dec1[0].text
                    : "Esquare team works together with the client as a partner to help provide solutions which fit their requirements in any environment. We start right from beginning by understanding the clients requirements, Planning and designing, Developing and implementing the solutions with any type and number of integrations by creating a one stop shop for our clients.Our highly skilled developers have worked on Open Source for several years, and helped in implementing many complex projects and development challenges."
                }
              />
            </div>
          </div>
        </div>
      </div>





      <div
        id="rs-about"
        className="rs-about style4 pt-40 pb-30 md-pt-10 md-pb-10"
      >
        <div className="container">
          <div className="row justify-content-between " style={{flexDirection:'row-reverse'}}>
            
    
          <div className="col-lg-6 md-mb-20">
            <div
              data-aos="zoom-in"
              data-aos-duration="1500"
              data-aos-delay="350"
            >
              <div className="img-part">
                <img
                  className="about-main"
                  src={
                    ourservice
                      ? ourservice.body[0].primary.serviceimg2.url
                      : AboutImage1
                  }
                  alt="About Image"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
              <div className="about-content">
                <SectionTitle
                  titleClass="title mb-10 sm-mb-6"
                  animateName="slide-right"
                  animateDuration="1200"
                  title={
                    ourservice
                      ? ourservice.body[0].primary.text2[0].text
                      : "Mobile Application Development"
                  }
                  descClass="desc mb-26"
                  description={
                    ourservice
                      ? ourservice.body[0].primary.dec2[0].text
                      : "list of technologies like- IOS,Andriod,Blackberry, Symbian,windows etc Mobile application development is the set of processes and procedures involved in writing software for small, wireless computing devices such as smartphones or tablets. It is similar to Web application development and has its roots in more traditional software development.It is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones."
                  }
                />
              </div>
            </div>
        </div>
        </div>
      </div>





      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-6 md-mb-20">
            <div
              data-aos="zoom-in"
              data-aos-duration="1500"
              data-aos-delay="350"
            >
              <div className="img-part">
                <img
                  className="about-main"
                  src={
                    ourservice
                      ? ourservice.body[0].primary.serviceimg3.url
                      : AboutImage2
                  }
                  alt="About Image"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content">
              <SectionTitle
                titleClass="title mb-2 sm-mb-6"
                animateName="slide-right"
                animateDuration="1200"
                title={
                  ourservice
                    ? ourservice.body[0].primary.text3[0].text
                    : "IT Consulting & Services"
                }
                descClass="desc mb-26"
                description={
                  ourservice
                    ? ourservice.body[0].primary.dec3[0].text
                    : "IT Consulting services help design, develop and maintain the software applications of its clients on various open source solutions from Liferay, Alfresco, Joomla and Drupal. Our resources Architects, software developers, Web portal designers, database designers, project managers, QA Team, Business systems analysts offer either onsite or offshore consulting services, which can help client every step of the way for developing and deploying Open Source enterprise solutions end to end.  In management, information technology consulting as a field of activity focuses on advising organizations on how best to use information technology in achieving their business objectives."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
