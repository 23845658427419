import React from "react";
import Slider from "react-slick";
import SinglePost from "../../components/Blog/SinglePost";

import blogImg1 from "../../assets/img/blog/style2/op.jpg";
import blogImg2 from "../../assets/img/blog/style2/mg.jpg";
import blogImg3 from "../../assets/img/blog/style2/pr.jpg";


const Magentoblog = (props:any) => {
  const {Cardslice2}=props;

  const blogSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="container">
      <Slider {...blogSettings}>
        <SinglePost
          blogClass="blog-item"
          blogImage={Cardslice2?Cardslice2?.body[1].items[0].cardimages.url:blogImg1}
          blogTitle={Cardslice2?Cardslice2?.body[1].items[0].tittle[0].text:"Open-Source Ecommerce platform"}
          blogDesc={Cardslice2?Cardslice2?.body[1].items[0].subtittle[0].text:"Developers can implement the core files and extend its functionality by adding new plug-in modules provided by other developers." } />
        <SinglePost
          blogClass="blog-item"
          blogImage={Cardslice2?Cardslice2?.body[1].items[1].cardimages.url:blogImg2}
          blogTitle={Cardslice2?Cardslice2?.body[1].items[1].tittle[0].text:"Magento Commerce"}
          blogDesc={Cardslice2?Cardslice2?.body[1].items[1].subtittle[0].text:"Released April 11, 2016, Magento Commerce is a platform as a service. Magento 2 has many new and improved features, developer tools and its architecture is quite different from all the previous versions."}/>
        <SinglePost
          blogClass="blog-item"
          blogImage={Cardslice2?Cardslice2?.body[1].items[2].cardimages.url:blogImg3}
          blogTitle={Cardslice2?Cardslice2?.body[1].items[2].tittle[0].text:"Magento Commerce (On-Premises)"}
          blogDesc={Cardslice2?Cardslice2?.body[1].items[2].subtittle[0].text:"This product is designed for large businesses that require technical support with installation, usage, configuration, and troubleshooting."} />
      </Slider>
      </div>
    </React.Fragment>
  );
};

export default  Magentoblog;
