import { Link } from 'react-router-dom';

const SingleTeam = (props:any) => {
    const { teamClass, Image, Title, Designation } = props;
	return(
        <div className={teamClass ? teamClass : 'team-item'}>
            <img 
                src={Image} 
                alt={Title}
            />
            <div className="content-part">
                <h2 className="name">
                    <Link to=''>
                        {Title}
                    </Link>
                </h2>
                <span className="designation">{Designation}</span>
            </div>
        </div>
	)
}

export default SingleTeam