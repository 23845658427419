import { usePrismicDocumentByUID } from "@prismicio/react";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import mainImage from "../../../assets/img/blog/style2/2.png";


const Companyportal = () => {
  const [document] = usePrismicDocumentByUID("casestudiesitems","companyintranet");
  const slice7 = document && document?.data;




  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);
  
  
  
  
const bg = {
  backgroundImage: `url(${
    slice7 ? slice7?.body[0].primary.bgimg.url :"/cpny.jfif"})`,
  backgroundSize: "cover",
  backgroundPosition: "right",
  height: "550px",
  backgroundRepeat: " no-repeat",
};


const button1 = {
  backgroundColor: "#369dd2",
  color: "#FFFFFF",
  border: "none",
  fontWeight: "bold",
  padding: "7px 25px",
  borderRadius: "2px",
  marginLeft: "24px",
};
  return (
  <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "140px" }}
                  >
               {slice7
                      ? slice7?.body[0].primary.tittle[0].text
                      : "Case Study"}
               <p style={{fontSize: "20px"}}> {slice7
                        ? slice7?.body[0].primary.text[0].text
                        : "Company Intranet Portal"}</p> 
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-20">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <img
                  src={slice7 ? slice7?.body[1].primary.leftimg.url : mainImage}
                  alt="Main Image"
                  style={{ borderRadius: "30px", width: "100%" }}
                />
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle1[0].text
                  :"Business Need"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[0].list[0].text
                  : "Our Client has recently developed a Capital Works policy. This                is to formalise the governance around the process for schools to                undertake any capital works projects. (For example, new school                buildings or renovations) as there are many capital works                projects undertaken by the schools, a stricter process to                approve and manage these works is required by the central                administration."}
              </p>

              <h2 style={{ color: "	#800000" }}> {slice7
                  ? slice7?.body[2].primary.tittle2[0].text
                  : "Challenges"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[1].list[0].text
                  : "  The online Capital Works solution will become a new function                within the finance portal and will be relatively stand-alone.                Each school will be able to create and process their own Capital                Works projects and these will be centrally managed and approved.                The Capital Works process consists of 10 stages, each project                there are 10 stages of governance that the project will need to                follow."}
              </p>

              <h2 style={{ color: "	#800000" }}> {slice7
                  ? slice7?.body[2].primary.tittle3[0].text
                  : "Key Features"}</h2>

              <li> {slice7
                  ? slice7?.body[2].items[2].list[0].text
                  : "Fully customized application with rich user interface."}</li>

              
            <li>{slice7
                  ? slice7?.body[2].items[3].list[0].text
                  : "Dynamic securities for different roles."}</li>
            
              <li>{slice7
                  ? slice7?.body[2].items[4].list[0].text
                  : "Roles based permission to approve/decline the form."}</li>
            
              <li>{slice7
                  ? slice7?.body[2].items[5].list[0].text
                  : "School Regional base access to BM, RM and RD."}</li>
            
              <li>
            
              {slice7
                  ? slice7?.body[2].items[6].list[0].text
                  : "Form locking/unlocking will be applied to each section of the                form to ensure that the form sections are completed in the                correct sequence"}
              </li>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
            {slice7
              ? slice7?.body[3].primary.text[0].text
              :"Click Here For Full Brochure"}
            <button style={button1}>Download</button>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Companyportal;
