import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import iconimg1 from "../../assets/img/technologyinsidepic/ic1.png";
import iconimg2 from "../../assets/img/technologyinsidepic/ic2.png";
import iconimg3 from "../../assets/img/technologyinsidepic/ic3.png";
import iconimg4 from "../../assets/img/technologyinsidepic/i2.png";
import iconimg5 from "../../assets/img/technologyinsidepic/i1.png";

import iconimg6 from "../../assets/img/technologyinsidepic/i3.png";

import mainImage from "../../assets/img/technologyinsidepic/hand1.png";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import Bigblog from "./Bigblog";
import Androidblog from "./Androidblog";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Android = () => {
  const [document] = usePrismicDocumentByUID("technologies","androidid");
  const slice33 = document && document?.data;


  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice33?slice33?.body[0].primary.bgimg1.url:"/android.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",

    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice33?slice33?.body[0].primary.tittle[0].text:"Android"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                  {slice33?slice33?.body[0].primary.text1[0].text:"  Android is a Linux-based operating system designed primarily                    for touchscreen mobile devices such as smartphones and                    tablet computers.                    Android is open source and Google releases the code under                    the Apache License. This open source code and permissive                   licensing allows the software to be freely modified and                    distributed by device manufacturers, wireless carriers and                    enthusiast developers."}
                    <br />
                    <br />
                    {slice33?slice33?.body[0].primary.text2[0].text: "Android is a complete set of software for mobile devices                    such as tablet computers, notebooks, smartphones, electronic                    book readers, set-top boxes.                    It provides many interesting features like weather details,                    opening screen, live RSS (Really Simple Syndication) feeds                    etc. Lot of android widgets with simplified examples such as                    Button, EditText, AutoCompleteTextView, ToggleButton,                    DatePicker, TimePicker, ProgressBar etc."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="rs-blog"
        className="rs-blog style2 pt-40 pb-10 md-pt-70 md-pb-20 mbl-mt--30"
      >
        <Androidblog  Cardslice2={slice33} />
      </div>

      <div
        id="rs-features"
        className="rs-features style4 pt-40  md-pt-20 mbl-mb-20"
        style={{ background: "#202829", paddingBottom: "20px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                <img src={slice33?slice33?.body[2].items[0].icons.url:iconimg1} alt="Image" />
                </div>
                <div className="content-part">
                  <h4 className="title">
                    <span className="watermark" style={{ color: "#ffc266" }}>
                    {slice33?slice33?.body[2].items[0].tittle[0].text:"Android, the world's most popular mobile platform:"}
                    </span>
                  </h4>
                  <p className="dese" style={{ color: "#ffff" }}>
                  {slice33?slice33?.body[2].items[0].subtittle[0].text:"Android powers hundreds of millions of mobile devices in                    more than 190 countries around the world. It's the largest                    installed base of any mobile platform and growing fast—every                    day another million users power up their Android devices for                    the first time and start looking for apps, games, and other                    digital content."}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mbl-mt-40">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                <img src={slice33?slice33?.body[2].items[1].icons.url:iconimg2} alt="" />
                </div>
                <div className="content-part">
                  <h4 className="title">
                    <span className="watermark" style={{ color: "#ffc266" }}>
                    {slice33?slice33?.body[2].items[1].tittle[0].text:"Global partnerships and large installed base"}
                    </span>
                  </h4>
                  <p className="dese" style={{ color: "#ffff" }}>
                  {slice33?slice33?.body[2].items[1].subtittle[0].text:" Building on the contributions of the open-source Linux                    community and more than 300 hardware, software, and carrier                    partners, Android has rapidly become the fastest-growing                    mobile OS Every day more than 1 million new Android devices                    are activated worldwide."}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mbl-mt-40">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                <img src={slice33?slice33?.body[2].items[2].icons.url:iconimg3} alt="" />
                </div>
                <div className="content-part">
                  <h4 className="title">
                    <span className="watermark" style={{ color: "#ffc266" }}>
                    {slice33?slice33?.body[2].items[2].tittle[0].text:" Powerful development framework"}
                    </span>
                  </h4>
                  <p className="dese" style={{ color: "#ffff" }}>
                  {slice33?slice33?.body[2].items[2].subtittle[0].text:"  Easily optimize a single binary for phones, tablets, and                    other devices. Android gives you everything you need to                    build best-in-class app experiences. It gives you a single                    application model that lets you deploy your apps broadly to                    hundreds of millions of users across a wide range of                    devices—from phones to tablets and beyond."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-30">
              <img src={slice33?slice33?.body[3].primary.leftimg.url:mainImage} alt="Main Image" />
            </div>

            <div className="col-lg-6 col-md-6 mb-10">
              <div className="row">
                <div
                  className="col-lg-5 col-md-2 mb-50"
                  style={{
                    marginBottom: "15px",
                    background: "#F1F1F1",
                    marginRight: "15px",
                    padding: "15px",
                    textAlign: "center",
                  }}
                >
                  <div
                    className="icon-part"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "15%",
                    }}
                  >
                    <img src={slice33?slice33?.body[3].items[0].icon_img.url:iconimg5} alt="Image" />
                  </div>
                  {slice33?slice33?.body[3].items[0].texts[0].text:" Effective online marketing will put your products or services                  in front of the people who really need them. Even if you don’t                  sell anything directly online, your business can still benefit                  from an internet marketing strategy that tells the world’s                  biggest marketplace all about whom you are and what you’ve got                  to offer."}
                </div>
                <div
                  className="col-lg-6 col-md-2 mb-30"
                  style={{
                    marginBottom: "15px",
                    background: "#F1F1F1",
                    padding: "15px",
                    textAlign: "center",
                  }}
                >
                  <div
                    className="icon-part"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "15%",
                    }}
                  >
                    <img src={slice33?slice33?.body[3].items[1].icon_img.url:iconimg4} alt="Image" />
                  </div>
                  {slice33?slice33?.body[3].items[1].texts[0].text:" Clean architecture is a great way to start thinking about                  architecture for apps. Using S.O.L.I.D. principles is a                  philosophy that motivates developers to think of architecture                  in terms of intents rather than frameworks and build software                  that is independent of UI, database or libraries. UI changes                  much faster than databases, and it’s important to decouple                  former from latter to easily make changes without affecting                  the latter."}
                </div>
              </div>
              <div
                className="col-lg-12 col-md-2 mb-20"
                style={{
                  marginLeft: "-10px",
                  background: "#F1F1F1",
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                <div
                  className="icon-part"
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "10%",
                  }}
                >
                  <img src={slice33?slice33?.body[3].items[2].icon_img.url:iconimg6} alt="Image" />
                </div>
              { slice33?slice33?.body[3].items[2].texts[0].text:"  As an open marketplace, Google Play puts you in control of how               you sell your products. You can publish whenever you want, as                often as you want, and to the customers you want. You can                distribute broadly to all markets and devices or focus on                specific segments, devices, or ranges of hardware capabilities."}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Android;
