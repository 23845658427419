import React, { useEffect } from "react";

import img1 from "../../assets/img/technologyinsidepic/technical.jpg";
import img2 from "../../assets/img/technologyinsidepic/confidence.jpg";
import img3 from "../../assets/img/technologyinsidepic/tool.jpg";
import Aos from "aos";
import CardSingle from "../Services/Card/CardSingle";
import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const DotNet = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","dotnetid");
  const slice5 = document && document?.data;
  console.log(slice5);

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/dotnet.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/mob2.gif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundSize: "cover",
    backgroundPosition: "cover",
    backgroundImage: `url(${slice5?slice5?.body[3].primary.listimage.url:"/black1.jpg"})`,
    backgroundRepeat: " no-repeat",
    
  };
  const whiteColor = {
    color: "white",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60  " style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                     {slice5?slice5?.body[0].primary.tittle[0].text:"Dot Net (.Net)"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:".NET is a framework developed by Microsoft that allows developers to create a wide range of applications for the Windows operating system using different programming languages like C#, F#, and Visual Basic. It includes a runtime environment, a set of class libraries, and tools for developing and deploying applications. The runtime environment manages the execution of code and provides services such as memory management, security, and exception handling."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"The class libraries are a set of pre-written code that developers can use to perform common tasks. The framework also includes features for improving the security and performance of applications."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#4f82c2" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Outstanding Technical Support"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text: "Providing dependable technical assistance and timely customer care are two of the main services offered by net development companies."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--60">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Confidence"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"One of the primary reasons for this is that service providers give coding help. A customer may employ an IT expert and request assistance developing their Dot Net application in exchange for confidentiality."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--40"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                textClass={whiteColor}
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Tools Benefits"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"The programmers at Dot Net Development can create their products using the technologies they utilize. They can develop a diverse variety of apps that are accessible to consumers and require a lot less initial expenditure."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-4 "
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Flexible Deployment"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:" Flexible deployment is one of the most important .NET Core capabilities. The modular design considers all of the dependencies you’ll need. It’s as simple as copying a folder to get started."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--80">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Interoperability"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Interoperability allows developers of the .NET development company to keep and benefit from current unmanaged codes."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--120"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Growth potential"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"It enables businesses to save money on new product development by avoiding the expenses associated with creating a product from the start. Net development services enable the creation of new applications that benefit consumers."}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-30 pb-80 md-pt-10 md-pb-300 mbl-pb-520"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Key Features of .Net:"}
                portalList="portal-none"
                ecommerce="ecommerce-none"
                twoList="twoList"
                cloudList=""
                listtwo="listtwo-none"
                listthree="listthree-none"
                list1={slice5?slice5?.body[3].items[0].lists[0].text:".NET framework allows developers to use multiple programming languages to create a single application."}
                list2={slice5?slice5?.body[3].items[1].lists[0].text:"This framework is designed to support object-oriented programming (OOP) concepts, such as inheritance, polymorphism, and encapsulation, which makes it easier for developers to create reusable and maintainable code."}
                list3={slice5?slice5?.body[3].items[2].lists[0].text:"The .NET framework includes a large set of pre-written class libraries that developers can use to perform common tasks such as reading and writing to files, connecting to databases, and working with the user interface. "}
                list4={slice5?slice5?.body[3].items[3].lists[0].text:"This framework includes features such as code access security and role-based security to help prevent unauthorized access to resources and protect sensitive data."}
                list5={slice5?slice5?.body[3].items[4].lists[0].text:"This framework includes a garbage collector that automatically manages the memory used by an application, which helps to prevent memory leaks and improve performance."}
                list6={slice5?slice5?.body[3].items[5].lists[0].text:"This framework includes a number of tools for developing and deploying applications, such as Visual Studio, which is an integrated development environment (IDE) for creating .NET applications."}
                list13={slice5?slice5?.body[3].items[6].lists[0].text:"Code written in one language can easily interact with code written in another language."}
                
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default DotNet;
