import React, { useEffect } from "react";

import img1 from "../../assets/img/technologyinsidepic/speed.jpg";
import img2 from "../../assets/img/technologyinsidepic/google.jpg";
import img3 from "../../assets/img/technologyinsidepic/high.jpg";
import Aos from "aos";
import CardSingle from "../Services/Card/CardSingle";
import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Angular = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","angularid");
  const slice5 = document && document?.data;
  console.log(slice5);

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/Angularimg.png"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/black1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundSize: "cover",
    backgroundPosition: "cover",
    backgroundImage: `url(${slice5?slice5?.body[3].primary.listimage.url:"/bg1.jfif"})`,
    backgroundRepeat: " no-repeat",
    
  };
  const whiteColor = {
    color: "white",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60  " style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                     {slice5?slice5?.body[0].primary.tittle[0].text:"Angular"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:"Angular is a JavaScript framework for building web applications. It is developed and maintained by Google and is used for creating dynamic, interactive single-page applications (SPAs)."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"Angular uses a component-based architecture and a declarative approach to development, making it easier to build and maintain large-scale projects. It also includes features such as dependency injection, two-way data binding, and a powerful template system. Angular is often used in conjunction with other technologies such as TypeScript and RxJS to build robust and scalable web applications."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-0 md-pt-30 md-pb-0"
        style={{ background: "#4f82c2" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-0"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Improved Speed and Performance"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text: "The diversity of Angular capabilities, for instance, template syntax, Angular CLI, routers, etc. make programmer’s work easier and enable quick loading of the application."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Google Support"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Google created Angular to use it internally for its official websites and solve problems in Google's internal systems "}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-20 mbl-mt--40"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                textClass={whiteColor}
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"High Quality of the Application"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"Angular is a complex platform that’s difficult to learn, so it requires proper qualifications from a developer. Numerous structural elements that include Injectors, Components, Directives, Pipes, Services, etc. might be challenging for beginning developers to learn."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-4 mbl-mb--60 "
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-120 mbl-mt-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Efficient Problem-Solving Patterns"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:" Angular offers powerful DI (dependency injection) instrument and services to resolve various productivity issues and speed up the development process"}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--130">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Excellent Material Design Library"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Programmers choose Angular Material because once they learned it, it gets easier and faster to incorporate Angular design elements in further projects."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--70"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Faster Development Process"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"The Angular framework allows a developer to build Angular web apps faster and make them highly efficient due to the technical advantages offered by the framework."}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-30 pb-80 md-pt-10 md-pb-390"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Key Features of Angular:"}
                portalList="portal-none"
                ecommerce="ecommerce-none"
                twoList="twoList"
                cloudList=""
                listtwo="listtwo-none"
                listthree="listthree-none"
                list1={slice5?slice5?.body[3].items[0].lists[0].text:"Angular uses a modular, component-based approach to building web applications, making it easy to organize and maintain large-scale projects."}
                list2={slice5?slice5?.body[3].items[1].lists[0].text:"Angular provides a two-way data binding feature, which allows changes made in the model to be automatically reflected in the view, and vice versa."}
                list3={slice5?slice5?.body[3].items[2].lists[0].text:"Angular uses a template-based approach to building user interfaces, which allows developers to create reusable components and dynamic views.               "}
                list4={slice5?slice5?.body[3].items[3].lists[0].text:"Angular provides a testing framework, which allows developers to easily write unit tests for their components and services."}
                list5={slice5?slice5?.body[3].items[4].lists[0].text:"Angular is written in TypeScript, which is a typed superset of JavaScript that offers better code readability and maintainability."}
                list6={slice5?slice5?.body[3].items[5].lists[0].text:"Angular allows developers to build web, desktop and mobile apps using the same codebase."}
                list13={slice5?slice5?.body[3].items[6].lists[0].text:"Angular has a powerful CLI(Command Line Interface) that allows developers to easily build mobile apps for iOS and Android platforms using the same codebase."}
                
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Angular;
