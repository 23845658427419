import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/managed.jpg";
import img2 from "../../assets/img/serviceinsidepics/process.jpg";
import img3 from "../../assets/img/serviceinsidepics/stratey.jpg";
import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import { useAllPrismicDocumentsByType, usePrismicDocumentByUID } from "@prismicio/react";

const MarketingPartner = (props: any) => {

  const [document] = usePrismicDocumentByUID("markatingpartner", "marketingid");
  const slice4 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${
      slice4 ? slice4?.body[0].primary.main_bg.url : "/marketpartner.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "right",

    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${
      slice4 ? slice4?.body[2].primary.bg_img.url : "/web2.jfif"
    })`,
    backgroundSize: "cover",
    height: "250px",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${
      slice4 ? slice4?.body[3].primary.bgimg3.url : "/bns2.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60 mbl-mb--20" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 md-pt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice4
                      ? slice4?.body[0].primary.main_tittle[0].text
                      : " Marketing Partners"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    {slice4
                      ? slice4?.body[0].primary.subtittle1[0].text
                      : "The world is changing at an accelerating pace. So we offer a                    client-driven mix of branding, business planning, business  development, fundraising, marketing, marketing communications, market research, organizational development,                    performance improvement, product innovation, quality management, public relations and project management  capabilities to both for-profit and not-for-profit clients."}
                    <br />
                    <br />
                    {slice4
                      ? slice4?.body[0].primary.subtittle2[0].text
                      : "Marketing Agency and Digital Marketing Services. Modern  Marketing Partners (MMP) provides comprehensive marketing  services, creative execution, and technical implementation for global brands, and startups alike. We're marketing                    communications problem solvers, with a bias toward results."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#4f82c2" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6  mb-30 mbl-mt-40"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={
                  slice4 ? slice4?.body[1].items[0].cardimages.url : "img1"
                }
                title={
                  slice4
                    ? slice4?.body[1].items[0].tittle[0].text
                    : "Managed Services"
                }
                subtitle={
                  slice4
                    ? slice4?.body[1].items[0].subtittle[0].text
                    : "Application management and support is most often an information technology (IT) services provider that manages and assumes responsibility for providing a defined set of services to its clients either proactively or as the MSP (not the client) determines that services are needed."
                }
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-pb-120">
              <CardSingle
                itemClass="courses-item"
                image={
                  slice4 ? slice4?.body[1].items[1].cardimages.url : "img2"
                }
                title={
                  slice4
                    ? slice4?.body[1].items[1].tittle[0].text
                    : "Process Implementation & Enhancement"
                }
                subtitle={
                  slice4
                    ? slice4?.body[1].items[1].subtittle[0].text
                    : "Function analytics, Budgeting & Consolidation design and implementation Process Implementation & Enhancement.is the process that turns strategies and plans into actions in order to accomplish strategic objectives and goals. Implementing your strategic plan is as important, or even more important, than your strategy."
                }
              />
            </div>
            <div
              className="col-lg-4 col-md-6 md-pt-50 mb-30 mbl-mt--100"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={
                  slice4 ? slice4?.body[1].items[2].cardimages.url : "img3"
                }
                title={
                  slice4
                    ? slice4?.body[1].items[2].tittle[0].text
                    : "Business Strategy"
                }
                subtitle={
                  slice4
                    ? slice4?.body[1].items[2].subtittle[0].text
                    : "Assessment, implementation roadmap, Information Management framework, BICC Framework Map the Process. Once you've decided which process you want to improve, document each step using a Flowchart."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-600 mbl-pb-590"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <h3 style={{ color: "white" }} className=" mbl-mb--10">
              {slice4
                ? slice4?.body[2].primary.tittle[0].text
                : "We focus on business improvement, principally in three areas"}
            </h3>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-pb-40 "
              data-aos="fade-right"
              data-aos-duration="1500"
              style={{ marginBottom: "-70px" }}
            >
              <TextSingle
                itemClass="courses-item"
                title={
                  slice4
                    ? slice4?.body[2].items[0].tittle[0].text
                    : "Market aligned"
                }
                subtitle={
                  slice4
                    ? slice4?.body[2].items[0].subtittle[0].text
                    : "Customer Focus, which includes using innovation, customer engagement and market research tools to create market-driven products and processes designed to gain and retain customer loyalty"
                }
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              style={{ marginBottom: "-70px" }}
            >
              <TextSingle
                itemClass="courses-item"
                title={
                  slice4
                    ? slice4?.body[2].items[1].tittle[0].text
                    : "Outcome driven"
                }
                subtitle={
                  slice4
                    ? slice4?.body[2].items[1].subtittle[0].text
                    : "Organizational Advancement, which views the organization itself as the product and focuses on developing both strategy and organizational capabilities"
                }
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={
                  slice4
                    ? slice4?.body[2].items[2].tittle[0].text
                    : "Enterprise of the Future"
                }
                subtitle={
                  slice4
                    ? slice4?.body[2].items[2].subtittle[0].text
                    : "Revenue Enhancement, which employs fundraising and marketing tools to identify and tap new sources of revenue"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-300"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mbl-pb-1 mb-100"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={
                  slice4
                    ? slice4?.body[3].primary.tittle[0].text
                    : "Leader Speak"
                }
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={
                  slice4
                    ? slice4?.body[3].primary.text1[0].text
                    : "We pride ourselves on being flexible, innovative and easy to work with. We have created subsidiary companies and joint ventures with interlinked specialties to focus more narrowly on business improvement and organizational development, market and opinion research, strategic planning and fundraising."
                }
                subtitle2={
                  slice4
                    ? slice4?.body[3].primary.text2[0].text
                    : "Make sure that your outsource partner is financially secure, as this is a critical factor in outsourcing. You could also find out more about the outsourcing service provider's directors and managers, their experience and their qualification. Geared with such detailed information, you will be able to analyze if a particular outsourcing service provider can match up to your expectations."
                }
                subtitle3={
                  slice4
                    ? slice4?.body[3].primary.text3[0].text
                    : " Ensure that the outsource partner that you choose speaks your language. Finding an outsource partner who can understand your language and communicate with you efficiently can help you understand their business objectives. Good communication can assure you about the outsourcing relationship."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default MarketingPartner;
