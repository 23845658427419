import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const RSMobileMenu = (props:any) => {
    const {setMenuOpen, menuOpen, parentMenu, secondParentMenu, headerFullWidth}=props;
    const location = useLocation();

	const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [course, setCourse] = useState(false)
	const [pages, setPages] = useState(false)
	const [team, setTeam] = useState(false)
	const [event, setEvent] = useState(false)
	const [gallery, setGallery] = useState(false)
	const [shop, setShop] = useState(false)
	const [otherPages, setOtherPages] = useState(false)
	const [blog, setBlog] = useState(false)
	const [blogSidebar, setBlogSidebar] = useState(false)
	const [blogSingle, setBlogSingle] = useState(false)
	const [contact, setContact] = useState(false)

	const openMobileMenu = (menu:any) => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'course') {
			setHome(false)
			setAbout(false)
			setCourse(!course)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(!pages)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'team') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(!team)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'event') {
			setHome(false)
			setPages(false)
			setAbout(false)
			setCourse(false)
			
			setTeam(false)
			setEvent(!event)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'gallery') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(!gallery)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'shop') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(!shop)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'otherPages') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(!otherPages)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(!blog)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blogSidebar') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(true)
			setBlogSidebar(!blogSidebar)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blogSingle') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(true)
			setBlogSidebar(false)
			setBlogSingle(!blogSingle)
			setContact(false)
		}
		else if (menu === 'contact') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(!contact)
		}
    }
    return ( <div className={headerFullWidth ? "container-fluid relative" : "container relative"}>
    <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
            <ul className="nav-menu">
                <li className={home ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                    <Link to="/" /**onClick={() => { openMobileMenu('home'); }} className={parentMenu === 'home' ? 'active-menu' : ''}**/>Home</Link>
                   
                </li>
                <li className={about ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                    <Link to="#" onClick={() => { openMobileMenu('about'); }} className={parentMenu === 'about' ? 'active-menu' : ''}>Technologies</Link>
                    <ul className={about ? "sub-menu current-menu" : "sub-menu"}>
                    <li>
                    <Link to="/Liferay">Liferay</Link>
                </li>
               
                <li>
                    <Link to="/Reacttech" >React</Link>
                </li>
                <li>
                    <Link to="/Angular" >Angular</Link>
                </li>
                <li>
                    <Link to="/Java" >Java</Link>
                </li>
                <li>
                    <Link to="/dotNet" >.Net</Link>
                </li>
                <li>
                    <Link to="/Springboot" >Spring Boot</Link>
                </li>
				<li>
                    <Link to="/Bigdata" >Big Data</Link>
                </li>
                <li>
                    <Link to="/Android" >Android</Link>
                </li>
                    </ul>
                </li>
                <li className={course ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                    <Link to="#" onClick={() => { openMobileMenu('course'); }} className={parentMenu === 'course' ? 'active-menu' : ''}>Solutions</Link>
                    <ul className={course ? "sub-menu current-menu" : "sub-menu"}>
                    <li>
                    <Link to="/Portal" className={location.pathname === "/course" ? "active-menu" : ""}>Portal</Link>
                </li>
                <li>
                    <Link to="/Cloud" className={location.pathname === "/course-2" ? "active-menu" : ""}>Cloud Computing</Link>
                </li>
                <li>
                    <Link to="/Content" className={location.pathname === "/course-3" ? "active-menu" : ""}>Content Management</Link>
                </li>
                <li>
                    <Link to="/Ecommerce" className={location.pathname === "/course-4" ? "active-menu" : ""}>E-Commerce</Link>
                </li>
                <li>
                    <Link to="/Management" className={location.pathname === "/course-5" ? "active-menu" : ""}>Business Management</Link>
                </li>
               
                    </ul>
                </li>
                <li className={pages ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                    <Link to="#" onClick={() => { openMobileMenu('pages'); }} className={parentMenu === 'course' ? 'active-menu' : ''}>Services</Link>
                    <ul className={pages ? "sub-menu current-menu" : "sub-menu"}>
                    <li>
                    <Link to="/WebPortal">Web Portal DevelopMent</Link>
                </li>
                <li>
                    <Link to="/WebDesign" >Web Designing</Link>
                </li>
                <li>
                    <Link to="/MobileApp" >Mobile App Development</Link>
                </li>
                <li>
                    <Link to="/CorparateTrain" >Corporate Training</Link>
                </li>
                <li>
                    <Link to="/WebHosting" >Web Hosting Services</Link>
                </li>
                <li>
                    <Link to="/OnlineMark" >Online Marketing</Link>
                </li>
                <li>
                    <Link to="/Business" >Business Consulting</Link>
                </li>
                
                    </ul>
                </li>
                <li className={blog ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                    <Link to="#" onClick={() => { openMobileMenu('blog'); }} className={parentMenu === 'course' ? 'active-menu' : ''}>ShowCase</Link>
                    <ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
                    <li>
                    <Link to="/CaseStudies" className={location.pathname === "/course" ? "active-menu" : ""}>Case Studies</Link>
                </li>
                <li>
                    <Link to="/Clientele" className={location.pathname === "/course-2" ? "active-menu" : ""}>Clientele</Link>
                </li>
                <li>
                    <Link to="/Brochure" className={location.pathname === "/course-3" ? "active-menu" : ""}>Brochure</Link>
                </li>
                <li>
                    <Link to="/Presentation" className={location.pathname === "/course-4" ? "active-menu" : ""}>Presentation</Link>
                </li>
                    </ul>
                </li>
                <li className={event ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                    <Link to="#" onClick={() => { openMobileMenu('event'); }} className={parentMenu === 'course' ? 'active-menu' : ''}>About</Link>
                    <ul className={event ? "sub-menu current-menu" : "sub-menu"}>
                    <li>
                    <Link to="/Company" className={location.pathname === "/course" ? "active-menu" : ""}>Company</Link>
                </li>
                <li>
                    <Link to="/Outsource	" className={location.pathname === "/course-2" ? "active-menu" : ""}>OutSourcing Partners</Link>
                </li>
                <li>
                    <Link to="/MarketingPartner" className={location.pathname === "/course-3" ? "active-menu" : ""}>Marketing Partners</Link>
                </li>
                <li>
                    <Link to="/BecomePartner" className={location.pathname === "/course-4" ? "active-menu" : ""}>Become A Partner</Link>
                </li>
                    </ul>
                </li>
                <li className={contact ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
                    <Link to="/Contact" onClick={() => { openMobileMenu('contact'); }} className={parentMenu === 'contact' ? 'active-menu' : ''}>Contact</Link>
                  
                </li>
            </ul>
        </div>
    </div>
</div> );
}

 
export default RSMobileMenu;