import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import mainImage from "../../assets/img/technologyinsidepic/imgbg1.jpg";
import Brand from "../../components/Common/Brand";

import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import Testimonial from "../home-6/TestimonialSection";

const Clientele = () => {
  const [document] = useAllPrismicDocumentsByType("clientele");
  const slice9 = document && document[0]?.data;
  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);

  
const bg = {
  backgroundImage: `url(${slice9?slice9?.body[0].primary.bgimg1.url:"/clientele.jfif"})`,
  backgroundSize: "cover",
  backgroundPosition: "right",
  height: "450px",
  backgroundRepeat: " no-repeat",
};
const bg1 = {
  backgroundImage: `url(${slice9?slice9?.body[1].primary.showimage.url:"/map.jpg"})`,
  backgroundSize: "cover",
  backgroundPosition: "cover",
  maxWidth: "100%",
  maxHeight: "100%",
  backgroundRepeat: " no-repeat",
};

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "130px" }}
                  >
                    {slice9?slice9?.body[0].primary.tittle[0].text:"CLIENTELE"}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2
          style={{
            fontWeight: "400",
            fontFamily: "firstFont",
            fontSize: "39px",
            color: "#23343F",
            padding: "30px 10px 12px 10px ",
            textAlign: "center",
          }}
        >
          {slice9?slice9?.body[1].primary.tittle1[0].text:"Our Client’s Across The Globe"}
        </h2>
      </div>
      <div style={bg1}>
        <img src="/map2.gif" alt="" />
      </div>
    
      {/* <div className="container mbl-pl-50 mr--10">

      <div className="content pt-20">
        <div className="row" >
            <div className="card col-lg-4">
              <div className="icon">
                <i className={slice9?slice9?.body[2].items[0].icons[0].text:"fa fa-globe indusIcon1"}></i>
              </div>
              <p className="title">{slice9?slice9?.body[2].items[0].tiitles[0].text:"Travel"}</p>
            
            </div>
            <div className="card col-lg-4">
              <div className="icon">
                <i className={slice9?slice9?.body[2].items[1].icons[0].text:"fa fa-university indusIcon1"}></i>
              </div>
              <p className="title">{slice9?slice9?.body[2].items[1].tiitles[0].text:"Government"}</p>
          
            </div>
            <div className="card col-lg-4">
              <div className="icon">
                <i className={slice9?slice9?.body[2].items[2].icons[0].text:"fa fa-graduation-cap indusIcon1"}></i>
              </div>
              <p className="title">{slice9?slice9?.body[2].items[2].tiitles[0].text:"Education"}</p>
              
            </div>
            <div className="card col-lg-4">
              <div className="icon">
              <i className={slice9?slice9?.body[2].items[3].icons[0].text:"fa fa-hospital-o indusIcon1"}></i>
              </div>
              <p className="title">{slice9?slice9?.body[2].items[3].tiitles[0].text:"Healthcare"}</p>
          
            </div>
            <div className="card col-lg-4">
              <div className="icon">
              <i className={slice9?slice9?.body[2].items[4].icons[0].text:"fa fa-industry indusIcon1"}></i>
              </div>
              <p className="title">{slice9?slice9?.body[2].items[4].tiitles[0].text:"Manufacturing"}</p>
             
            </div>
            <div className="card col-lg-4">
              <div className="icon">
              <i className={slice9?slice9?.body[2].items[5].icons[0].text:"fa fa-shopping-cart indusIcon1"}></i>
              </div>
              <p className="title">{slice9?slice9?.body[2].items[5].tiitles[0].text:"Ecommerce"}</p>
          
            </div>
          </div>
        </div>
       
      </div> */}
      <Brand/>
      <Testimonial />
    </React.Fragment>
  );
};

export default Clientele;
