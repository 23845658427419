import React from "react";
import Slider from "react-slick";
import SinglePost from "../../components/Blog/SinglePost";

import blogImg1 from "../../assets/img/blog/style2/digital.jpg";
import blogImg2 from "../../assets/img/blog/style2/html.jpg";
import blogImg3 from "../../assets/img/blog/style2/sass.jpg";


const Bigblog = (props:any) => {
 const {Cardslice2}=props;


  const blogSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment> <div className="container">
      <Slider {...blogSettings}>
        <SinglePost
          blogClass="blog-item"
          blogImage={Cardslice2?Cardslice2?.body[1].items[0].cardimages.url:blogImg1}
          blogTitle={Cardslice2?Cardslice2?.body[1].items[0].tittle[0].text:"Saas/Cloud Computing"}
          blogDesc={Cardslice2?Cardslice2?.body[1].items[0].subtittle[0].text:"Esquare’s Hybrid IT Solutions provide an Automated and Orchestrated provisioning and management of Public, Private Cloud"}
        />
        <SinglePost
          blogClass="blog-item"
          blogImage={Cardslice2?Cardslice2?.body[1].items[1].cardimages.url:blogImg2}
          blogTitle={Cardslice2?Cardslice2?.body[1].items[1].tittle[0].text:"HTML5/CSS3"}
          blogDesc={Cardslice2?Cardslice2?.body[1].items[1].subtittle[0].text:"The traditional workplace is fast transforming into a workplace-on the-move model where employees expect to work"}
        />
        <SinglePost
          blogClass="blog-item"
          blogImage={Cardslice2?Cardslice2?.body[1].items[2].cardimages.url:blogImg3}
          blogTitle={Cardslice2?Cardslice2?.body[1].items[2].tittle[0].text:"Digital ecosystem architecture"}
          blogDesc={Cardslice2?Cardslice2?.body[1].items[2].subtittle[0].text:"Digital transformation and Bi-modal IT are the recent trends influencing every business."}
        />
      </Slider>
      </div>
    </React.Fragment>
  );
};

export default Bigblog;
