import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/HeaderMain/Header";
import Footer from "../../components/Layout/Footer/FooterStyleTwo";
import ScrollToTop from "../../components/Common/ScrollTop";
import ContactMain from "./ContactMain";
import Team from "./TeamSection";
import Background from "../../components/Contact/backgroundcontact";
import { useAllPrismicDocumentsByType } from "@prismicio/react";

const ContactFour = () => {
  const [document] = useAllPrismicDocumentsByType("contactus");
  const slicecontact = document && document[0]?.data;
  console.log(slicecontact);
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      {/* Background Section Start */}
      <Background bg={slicecontact}/>
      {/* Background Section End */}

      {/* Backgroundimages Section Start */}
      <Team location={slicecontact} />
      {/* Backgroundimages Section End */}

      {/* ContactMain Section Start */}
      <ContactMain contact_Details={slicecontact} />
      {/* ContactMain Section End */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default ContactFour;
