import React from "react";
import Slider from "react-slick";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleTestimonial from "../../components/Testimonial/SingleTestimonial";

import quote from "../../assets/img/testimonial/style5/quote.png";
import author1 from "../../assets/img/testimonial/style5/1.png";
import author2 from "../../assets/img/testimonial/style5/2.png";
import author3 from "../../assets/img/testimonial/style5/3.png";
import author4 from "../../assets/img/testimonial/style5/4.png";

import bgImg from "../../assets/img/bg/testi-bg.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
  "background-size": "cover",
  "background-repeat": "no-repeat",
};

const Testimonial = (props:any) => {
  const {Clientsays}=props;

  const testimonialSettings = {
    dots: true,
    centerMode: false,
    infinite: true,
    arrows: false,
    autoplay:true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div
        className="rs-testimonial style5 gray-bg pt-30 pb-40 md-pt-2 md-pb-10"
        style={bgStyle}
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title mb-14 text-center"
            bottomSubTitleClass="sub-title capitalize"
            bottomSubTitle={Clientsays?Clientsays.body[6].primary.tittle1[0].text:"Our Clients Review"}
            titleClass="title extra-bold mb-14 sm-mb-10 orange-color"
            title={Clientsays?Clientsays.body[6].primary.tittle2[0].text:"What Our Client Says"}
            animateName="fade-down"
            animateDuration="1200"
          />
          <Slider {...testimonialSettings}>
            <SingleTestimonial
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={Clientsays?Clientsays.body[6].items[0].images.url:author1}
              Title={Clientsays?Clientsays.body[6].items[0].names[0].text:"David"}
              Designation={Clientsays?Clientsays.body[6].items[0].from[0].text:"From Australia"}
              Description={Clientsays?Clientsays.body[6].items[0].desc[0].text:"We are really happy with what you and the team have delivered so far, we really appreciate all the work you have put into this and your flexibility."}
            />
            <SingleTestimonial
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={Clientsays?Clientsays.body[6].items[1].images.url:author2}
              Title={Clientsays?Clientsays.body[6].items[1].names[0].text:"Khayahan"}
              Designation={Clientsays?Clientsays.body[6].items[1].from[0].text:"From Turkey"}
              Description={Clientsays?Clientsays.body[6].items[1].desc[0].text:"Esquare will be our preferred Vendor for all future Liferay Stuff."}
            />
            <SingleTestimonial
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={Clientsays?Clientsays.body[6].items[2].images.url:author3}
              Title={Clientsays?Clientsays.body[6].items[2].names[0].text:"Matthias"}
              Designation={Clientsays?Clientsays.body[6].items[2].from[0].text:"From Germany"}
              Description={Clientsays?Clientsays.body[6].items[2].desc[0].text:"It was a great experience working with Esquare &Team and will look forward to working with you in the future."}
            />
            <SingleTestimonial
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={Clientsays?Clientsays.body[6].items[3].images.url:author4}
              Title={Clientsays?Clientsays.body[6].items[3].names[0].text:"Ghouse"}
              Designation={Clientsays?Clientsays.body[6].items[3].from[0].text:"From United States"}
              Description={Clientsays?Clientsays.body[6].items[3].desc[0].text:"Thanks for all your dedicated support and cooperation."}
            />
            <SingleTestimonial
              itemClass="testi-item"
              quoteImage={quote}
              authorImage={Clientsays?Clientsays.body[6].items[4].images.url:author4}
              Title={Clientsays?Clientsays.body[6].items[4].names[0].text:"Sumitra"}
              Designation={Clientsays?Clientsays.body[6].items[4].from[0].text:"From United States"}
              Description={Clientsays?Clientsays.body[6].items[4].desc[0].text:"Esquare Team supported me at every moment whenever there was a deadlock, and with a quick turn around time."}
            />
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
