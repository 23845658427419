import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/host1.jpg";
import img2 from "../../assets/img/serviceinsidepics/host2.jpg";
import img3 from "../../assets/img/serviceinsidepics/host3.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const BecomePartner = () => {
  const [document] = usePrismicDocumentByUID("markatingpartner", "becomeid");
  const slice4 = document && document?.data;

 

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${
      slice4 ? slice4?.body[0].primary.main_bg.url : "/becomepartner.jfif"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "right",
    backgroundRepeat: " no-repeat",
  };
  
  const NormalTextbg = {
    backgroundImage: `url(${slice4?slice4?.body[2].primary.bgimg3.url:"/host2.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 pt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice4
                      ? slice4?.body[0].primary.main_tittle[0].text
                      : "Become a Esquare Partner"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    {slice4
                      ? slice4?.body[0].primary.subtittle1[0].text
                      : "Join a community of recognized technology leaders and provide your customers with industry leading tools to  deliver secure data and apps. By becoming a Esquare partner  you can expand your business opportunities in new lucrative markets. Plan, deploy and manage Esquare solutions to increase profitability - for your business as well as your  customer’s."}
                    <br />
                    <br />
                    {slice4
                      ? slice4?.body[0].primary.subtittle2[0].text
                      : "A leading outsourced services provider, has put in place an                    effective business model, a fact borne out by the                    organization's world-class service delivery to its global                    clientele. Our customers, across a range of verticals, have                    benefited from increased productivity, sales, customer  satisfaction, business value.    If your offshore partner has experience in delivering the                    required services at the scale of your business needs. This                    can help you analyze if the particular outsourcing service                    provider is best suited for your business."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#2AAAE6" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-10"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice4?slice4?.body[1].items[0].cardimages.url:"img1"}
              title={
                  slice4
                    ? slice4?.body[1].items[0].tittle[0].text
                    :"Service Provider"}
                subtitle={
                  slice4
                    ? slice4?.body[1].items[0].subtittle[0].text
                    :"Provide Esquare's virtualization solutions, including hosted desktops and applications, on a rental, subscription or services basis is a technology partner that helps brand manufacturers streamline the process of getting product data to retailers."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-10">
              <CardSingle
                itemClass="courses-item"
                image={slice4?slice4?.body[1].items[1].cardimages.url:"img2"}
                title={
                  slice4
                    ? slice4?.body[1].items[1].tittle[0].text
                    :"Solution advisor"}
                subtitle={
                  slice4
                    ? slice4?.body[1].items[1].subtittle[0].text
                    :"Earn the trust of your customers as an expert in solutions to enable mobile workstyles, and earn incentives recognizing your commitment and success.Customer service is the service we provide fellow employees and other departments within our own organizations, as well as our suppliers and anyone else with whom we work to get our jobs done."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 md-pt-30 mb-30"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice4?slice4?.body[1].items[2].cardimages.url:"img3"}
                title={
                  slice4
                    ? slice4?.body[1].items[2].tittle[0].text
                    :"SaaS Referral Partner"}
                subtitle={slice4
                ? slice4?.body[1].items[2].subtittle[0].text
                :"Recommend our industry-leading small business SaaS solutions to your customers and receive generous rewards for every qualifying referral.Referral software to help you get more customers. Quickly & easily integrate a referral program into your SaaS application or subscription website."}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-180"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice4?slice4?.body[2].primary.tittle[0].text:"Client Speak"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice4?slice4?.body[2].primary.text1[0].text:"“The catalog is very impressive. Navigation is extremely fast, the image loading and flow, as we traverse the catalog, is very impressive”"}
                subtitle2={slice4?slice4?.body[2].primary.text2[0].text:"The most exciting aspect of today’s business environment is the ability to customize your business to fit your talents, values and strengths. Your performance spikes when you’re doing work you love.Your business can’t grow in a vacuum. The more people know about you and respect your work, the easier it is to create the critical mass needed to fast forward your business growth."}
                subtitle3={slice4?slice4?.body[2].primary.text3[0].text:"- Director of Operations of a Furniture retailer."}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default BecomePartner;
