import React from "react";
import bgImg from "../../assets/img/bg/contactus.jpg";


  
const Background = (props:any) => {
  const {bg}=props;


  const bgStyle = {
    backgroundImage: `url(${bg?bg.body[0].primary.bgimg.url:bgImg})`,
    "background-size": "cover",
    "background-position": "center",
    height: "100vh",
    display: "grid",
    "align-items": "center",

  };
    return ( 
        <React.Fragment>
<div id="rs-banner" className="rs-banner style3" style={bgStyle}>
        <div className="container pt-90 md-pt-50">
          <div className="banner-content pb-12">
            <div className="row">
              <div className="col-lg-7">
                <h1 className="banner-title white-color">{bg?bg.body[0].primary.tittle[0].text:"Contact Us"}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>


     );
}
 
export default Background;