import React from 'react';
import Slider from "react-slick";

import brandNormalLogo1 from '../../../assets/img/brand/1.png';
import brandNormalLogo2 from '../../../assets/img/brand/2.png';
import brandNormalLogo3 from '../../../assets/img/brand/3.png';
import brandNormalLogo4 from '../../../assets/img/brand/4.png';
import brandNormalLogo5 from '../../../assets/img/brand/5.png';
import brandNormalLogo6 from '../../../assets/img/brand/6.png';
import brandNormalLogo7 from '../../../assets/img/brand/7.png';
import brandNormalLogo8 from '../../../assets/img/brand/8.png';
import brandNormalLogo9 from '../../../assets/img/brand/9.png';
import brandNormalLogo10 from '../../../assets/img/brand/10.png';
import brandNormalLogo11 from '../../../assets/img/brand/11.png';
import brandNormalLogo12 from '../../../assets/img/brand/12.png';
import brandNormalLogo13 from '../../../assets/img/brand/13.png';
import brandNormalLogo14 from '../../../assets/img/brand/14.png';
import brandNormalLogo15 from '../../../assets/img/brand/15.png';

const Brand = (props:any) => {
    const {Brandslice}=props;
   
    const { brandClass } = props;

    const sliderSettings = {
        dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="rs-partner pt-10 md-pt-10 md-pb-50 footer-bg">
            <div className="container mt-20 mb--20">
                <Slider {...sliderSettings}>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[0].brandimg.url:brandNormalLogo1} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[1].brandimg.url:brandNormalLogo2} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[2].brandimg.url:brandNormalLogo3} alt="saxax" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[3].brandimg.url:brandNormalLogo4} alt="adssa" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[4].brandimg.url:brandNormalLogo5} alt="ssass" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[5].brandimg.url:brandNormalLogo6} alt="fdvfvvf" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[6].brandimg.url:brandNormalLogo7} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[7].brandimg.url:brandNormalLogo8} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[8].brandimg.url:brandNormalLogo9} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[9].brandimg.url:brandNormalLogo10} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[10].brandimg.url:brandNormalLogo11} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[11].brandimg.url:brandNormalLogo12} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[12].brandimg.url:brandNormalLogo13} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a >
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[13].brandimg.url:brandNormalLogo14} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="main-logo" src={Brandslice?Brandslice.body[5].items[14].brandimg.url:brandNormalLogo15} alt="" />
                            </a>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Brand;