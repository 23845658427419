import { usePrismicDocumentByUID } from "@prismicio/react";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Presentation = () => {
  const [document] = usePrismicDocumentByUID(
    "casestudiesitems",
    "presentationid"
  );
  const slice7 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${
      slice7 ? slice7?.body[0].primary.bgimg.url : "/present.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "right",
    height: "450px",
    backgroundRepeat: " no-repeat",
  };

  const button1 = {
    backgroundColor: "#369dd2",
    color: "#FFFFFF",
    border: "none",
    fontWeight: "bold",
    padding: "7px 25px",
    borderRadius: "2px",
    marginLeft: "24px",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "140px" }}
                  >
                    {slice7
                      ? slice7?.body[0].primary.tittle[0].text
                      : "Presentation"}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-20">
              <iframe
                src={
                  slice7
                    ? slice7?.body[1].primary.tittle2[0].text
                    : "https://www.youtube.com/embed/3ML5gRDtkC8?rel=0"
                }
                id="video"
                style={{ height: "260px", width: "100%" }}
              ></iframe>
            </div>
            <div
              className="col-lg-7 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#800000" }}>
                {slice7
                  ? slice7?.body[1].primary.tittle1[0].text
                  : "Areas of Expertise"}
              </h2>
              <p>
                <li>
                  {slice7
                    ? slice7?.body[1].items[0].list[0].text
                    : " Specializing in server technologies like database management."}
                </li>
                <li>
                  {slice7
                    ? slice7?.body[1].items[1].list[0].text
                    : "Web database design & maintenanace."}
                </li>
                <li>
                  {slice7
                    ? slice7?.body[1].items[2].list[0].text
                    : "Multi-user and multi-function corporate Intranet Portal                  system."}
                </li>
                <li>
                  {slice7
                    ? slice7?.body[1].items[3].list[0].text
                    : " Usage of Mobile Internet programming in the portal system."}
                </li>
                <li>
                  {slice7
                    ? slice7?.body[1].items[4].list[0].text
                    : "Extending the Web solutions with latest technologies."}
                </li>
              </p>

              <p
                style={{
                  fontSize: "14px",

                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {slice7
                  ? slice7?.body[2].primary.text[0].text
                  : "Click Here For Full Brochure"}
                <button style={button1}>Download</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Presentation;
