import { Link } from "react-router-dom";
const HealthCare = () => {
  return (
    <div className="webportal main-home mt-130 md-mt-60">
      <img
        src="/comingsoon.jpg"
        alt=""
        style={{ width: "100%", maxHeight: "auto" }}
      />
    </div>
  );
};

export default HealthCare;
