import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import iconimg3 from "../../assets/img/technologyinsidepic/globe.png";
import iconimg2 from "../../assets/img/technologyinsidepic/rocket.png";
import iconimg1 from "../../assets/img/technologyinsidepic/ecart.png";

import mainImage from "../../assets/img/technologyinsidepic/bg2.jpg";

import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import Bigblog from "./Bigblog";
import Magentoblog from "./Magentoblog";
import { usePrismicDocumentByUID } from "@prismicio/react";


const Magento = () => {

  const [document] = usePrismicDocumentByUID("technologies","magentoid");
  const slice33 = document && document?.data;
 console.log(slice33)

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);


  const bg = {
    backgroundImage: `url(${slice33?slice33?.body[0].primary.bgimg1.url:"/Meg.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice33?slice33?.body[3].primary.bg_and_normal_img.url:"/megbg.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
  
    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 pt-40">
                <div className="sec-title3 mb-40 ">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                  {slice33?slice33?.body[0].primary.tittle[0].text:"  Magento"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice33?slice33?.body[0].primary.text1[0].text:"Magento is an open-source eCommerce platform that offers                    great flexibility for customizing the look, content, and                    functionality of an online store.                                    The best thing about Magento is the robust set of features                    which are scalable and customizable for the convenience of                    buyers and sellers. It has a flexible modular architecture                    and is scalable with many control options that is helpful                    for users."}
                    <br />
                    <br />
                    {slice33?slice33?.body[0].primary.text2[0].text:"Magento uses E-commerce platform which offers organizations                    ultimate E-commerce solutions and extensive support network.                    Magento allows user to update E-commerce website                    automatically. It is simple, quick and versatile to use."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="rs-blog"
        className="rs-blog style2 pt-40 pb-40 md-pt-70 md-pb-20 mbl-mt--30"
        style={{
          backgroundColor: "#0098CA",
        }}
      >
        <Magentoblog Cardslice2={slice33}/>
      </div>

      <div
        id="rs-features"
        className="rs-features style4 pt-40  md-pt-20 mbl-mb-30"
        style={{ backgroundColor: "#ffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4 mbl-mt-30">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                <img src={slice33?slice33?.body[2].items[0].icons.url:iconimg1} alt="Image" />
                </div>
                <div className="content-part" style={{ textAlign: "center" }}>
                  <h4 className="title">
                    <span className="watermark orange-color">
                    {slice33?slice33?.body[2].items[0].tittle[0].text:" Magento PHP Developers"}
                    </span>
                  </h4>
                  <p className="dese ">
                  {slice33?slice33?.body[2].items[0].subtittle[0].text:"Our dedicated Magento team of experts will guide you through                    the different stages of your project from scope definition                    to deployment."}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mbl-mt-30">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                <img src={slice33?slice33?.body[2].items[1].icons.url:iconimg2} alt="" />
                </div>
                <div className="content-part" style={{ textAlign: "center" }}>
                  <h4 className="title">
                    <span className="watermark orange-color">
                    {slice33?slice33?.body[2].items[1].tittle[0].text:"Offshore Development"}
                    </span>
                  </h4>
                  <p className="dese ">
                  {slice33?slice33?.body[2].items[1].subtittle[0].text:"  We have a qualified team of Magento developers who can                    assist you in Magento E-commerce development and                    customization along with multiple online stores integration                    and many more things."}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mbl-mt-30">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                <img src={slice33?slice33?.body[2].items[2].icons.url:iconimg3} alt="" />
                </div>
                <div className="content-part" style={{ textAlign: "center" }}>
                  <h4 className="title">
                    <span className="watermark orange-color">
                    {slice33?slice33?.body[2].items[2].tittle[0].text:"Magento E-Commerce"}
                    </span>
                  </h4>
                  <p className="dese ">
                  {slice33?slice33?.body[2].items[2].subtittle[0].text:"We have a qualified team of Magento developers who can                    assist you in Magento E-commerce development and                    customization along with multiple online stores integration                    and many more things."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-5  0 md-pb-110"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#ffff" }}>
              {slice33?slice33?.body[3].primary.tittle[0].text:"Magento E-commerce development"}
              </h2>
              <p style={{ color: "	#ffff" }}>
              {slice33?slice33?.body[3].items[0].list_or_content[0].text:" We have a versatile and highly competent E-commerce tool and                uses it to provide solutions to store owners/merchants with                high-quality level"} 
                <br /> <br />
                {slice33?slice33?.body[3].items[1].list_or_content[0].text:"This transaction is between businesses and                consumers. In this type of transaction, merchants sell products                to consumers through shopping cart software."}
                <br /> <br />
                {slice33?slice33?.body[3].items[2].list_or_content[0].text:" In this type of                transaction, one consumer or customer interacts with other                consumers through internet."}
                 <br /> <br /> 
                 {slice33?slice33?.body[3].items[3].list_or_content[0].text:"This transaction is between the                consumer or the customer and businesses or organizations where                the consumer makes a product that the organization uses it to                complete the business. We have a rich experience in serving our                clients with a rich blend of creativity and functionality with                Magento customization"}
              </p>
            </div>
           
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Magento;
