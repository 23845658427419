import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/web1-1.jpg";
import img2 from "../../assets/img/serviceinsidepics/web1-2.jpg";
import img3 from "../../assets/img/serviceinsidepics/web1-3.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Cloud = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","cloudid");
  const slice5 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/C1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/black1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.listimage.url:"/web3.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                       {slice5?slice5?.body[0].primary.tittle[0].text:"Cloud Computing"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:"The term Cloud Computing is such a by-word nowadays with                    people claiming it's the trend of the future. The idea of                    cloud computing is that individuals and business need not be                    limited by applications in their desktops. The applications,                    services and utilities needed in the performance of your                    work can simply be hosted in the 'Cloud' or by a provider.                    You probably didn't know it but you are already part of the                    cloud computing generation if you have a simple online email                    subscription, which is not installed in your desktop."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"Esquare identifies the requirement for having cloud based                    hosting for our clients to have it accessed globally by the                    users. We provide end-end hosting solutions on the cloud.                    Esquare understands that one size does not fit all, hence we                    provide a customised solutions to our clients based on the                    clients requirements in partnership with Cloud Computing                    providers for any technology under the sun."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#6B5B95" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Cloud Solutions"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"Private Cloud Solutions: with dedicated resources for enhanced security, reliability & manageability from Partner like AWS. You can choose which services you use, one or several, and choose how you use them. "}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--40">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Secure"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"AWS is a secure, durable technology platform with industry-recognized certifications and audits: PCI DSS Level 1, ISO 27001, FISMA Moderate, HIPAA, and SAS 70 Type II. Our services and data centers have multiple layers of operational and physical security to ensure the integrity and safety of your data."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Open and Flexible"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"AWS is a language and operating system agnostic platform. You choose the development platform or programming model that makes the most sense for your business. This flexibility allows you to focus on innovation, not infrastructure."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-20"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Agility and Instant Elasticity"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"AWS provides a massive global cloud infrastructure that allows you to quickly innovate, experiment and iterate. Instead of waiting weeks or months for hardware, you can instantly deploy new applications, instantly scale up as your workload grows, and instantly scale down based on demand. Whether you need one virtual server or thousands, whether you need them for a few hours or 24/7, you still only pay for what you use."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Amazon Web Services"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Today, Amazon Web Services provides a highly reliable, scalable, low-cost infrastructure platform in the cloud that powers hundreds of thousands of businesses in 190 countries around the world. With data center locations in the U.S., Europe, Singapore, and Japan, customers across all industries are taking advantage of the following benefit"}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--40"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Low Cost"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"AWS offers low, pay-as-you-go pricing with no up-front expenses or long-term commitments. We are able to build and manage a global infrastructure at scale, and pass the cost saving benefits onto you in the form of lower prices. With the efficiencies of our scale and expertise, we have been able to lower our prices on 15 different occasions over the past four years."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-20"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-150"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Agility and Instant Elasticity"}
                listClass=""
                portalList=""
                cloudList=""
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                list1={slice5?slice5?.body[3].items[0].lists[0].text:"   Application Hosting."}
                list2={slice5?slice5?.body[3].items[1].lists[0].text:"      Backup and Storage."}
                list3={slice5?slice5?.body[3].items[2].lists[0].text:"  Content Delivery."}
                list4={slice5?slice5?.body[3].items[3].lists[0].text:"  Databases."}
                list5={slice5?slice5?.body[3].items[4].lists[0].text:"   E-Commerce."}
                list6={slice5?slice5?.body[3].items[5].lists[0].text:"     Enterprise IT."}
                list7={slice5?slice5?.body[3].items[6].lists[0].text:" High Performance Computing."}
                list8={slice5?slice5?.body[3].items[7].lists[0].text:" Media Hosting."}
                list9={slice5?slice5?.body[3].items[8].lists[0].text:"  On-Demand Workforce."}
                list10={slice5?slice5?.body[3].items[9].lists[0].text:" Search Engines."}
                list11={slice5?slice5?.body[3].items[10].lists[0].text:"  Web Hosting."}
                list12={slice5?slice5?.body[3].items[11].lists[0].text:" Media and Entertainment."}
                list13={slice5?slice5?.body[3].items[12].lists[0].text:"  Life Sciences."}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Cloud;
