import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/web2-1.png";
import img2 from "../../assets/img/serviceinsidepics/web2-2.png";
import img3 from "../../assets/img/serviceinsidepics/web2-3.png";


import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const WebDesign = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","webdesignid");
  const slice5 = document && document?.data;


  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);



  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/web2-1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
   
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/web2.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.bgimg3.url:"/web2-2.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };



  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-pt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                         {slice5?slice5?.body[0].primary.tittle[0].text:"Web Designing"} 
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                   {slice5?slice5?.body[0].primary.text1[0].text: "Esquare a professional firm for Website Designing Based in                    India, offers a wide range of Web Design Services India. We                    use a full-circle approach in Website Design. Our Website                    Design Company specializes in anything from basic Website                    Design to a wide variety of Web Site Design Services."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"The Internet offers new opportunities for marketing                    businesses. Esquare helps you take advantage of the vast                    possibilities of this new medium, it is imperative for                    companies to have an online presence which makes an impact                    and generates business. The growing competition among                    businesses to secure a dominant position on the Internet has                    triggered a race for superior Website Designing and                    Development services."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--50"
        style={{ background: "#34568B" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt-40"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Digital Automation"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"Our 20+ years of experience in influencing and delivering business outcomes to a diversified set of global customers proves our expertise."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30  mbl-mt--70">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Digital Mobile"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Mobility is at the core of everything digital. Multiple touchpoints across the target universe means there are new ways to connect and converse."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--50"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Digital Social"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"Listening into social conversations is not enough to help enterprises make informed decisions and maintain brand image."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-20 mbl-mb--30"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"CSS3 Development"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"Esquare has helped organizations across 25 countries simplify, standardize and globalize their business processes.Create Flexible, Interesting, and Usable Designs for Desktop &Mobile Websites with CSS tutorial for beginners using CSS3.Cascading Style Sheets (CSS) is a style sheet language used for describing the presentation of ..... Development of HTML, CSS, and the DOM had all been taking place in one group, the HTML Editorial Review Board (ERB)."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Adobe Flash/Flex"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Ensuring our clients cruise smoothly through their Oracle Cloud journey With worldwide expertise in Oracle SaaS.Apache Flex, formerly Adobe Flex, is a software development kit for the development and deployment of cross-platform rich Internet applications based on the Adobe Flash platform."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--70"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Adobe AIR"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"Empowers you with efficiency, flexibility and agility essential to transform your business.Adobe AIR is a cross-platform runtime system developed by Adobe Systems for building desktop applications and mobile applications, programmed using Adobe Animate, ActionScript and optionally Apache Flex."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-60 md-pt-10 md-pb-400"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Web Designing"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice5?slice5?.body[3].primary.text1[0].text:"In a situation like this, what your business needs is the support of a Website Design and Development company that offers technical experience combined with commitment to exceed your expectations. Esquare is a reputable name in web solutions. We offer state-of-the art Website Designing and Development services that can help you consolidate your online presence."}
                subtitle2={slice5?slice5?.body[3].primary.text2[0].text:"Web design encompasses many different skills and disciplines in the production and maintenance of websites. The different areas of web design include web graphic design; interface design; authoring, including standardised code and proprietary software; user experience design; and search engine optimization."}
                subtitle3={slice5?slice5?.body[3].primary.text3[0].text:" Web design is the process of creating websites. It encompasses several different aspects, including webpage layout, content production, and graphic design.web design is technically a subset of the broader category of web development."}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default WebDesign;
