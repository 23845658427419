import React, { useEffect } from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import DegreeSingle from "../../components/Degree/DegreeSingle";
// import Tilty from "react-tilty";
// Images
import image1 from "../../assets/img/degrees/1.jpg";
import image2 from "../../assets/img/degrees/2.jpg";
import image3 from "../../assets/img/degrees/3.jpg";
import image4 from "../../assets/img/degrees/4.jpg";
import image5 from "../../assets/img/degrees/5.jpg";
import AOS from 'aos';

const Courses = (props:any) => {

 const {technology}=props;



  
  useEffect(() => {
    AOS.init();
})
  return (
    <div className="rs-degree style1 modify gray-bg pt-50 pb-10 md-pt-50 md-pb-40">
     <div className="container"> 
     {/* <Tilty perspective={1200} reverse={true}> */}
        <div className="row y-middle">
          <div className="col-lg-4 col-md-6 mb-30">
            {/* Section Title Start */}
            <SectionTitle
              titleClass="title mb-0 orange-color"
              title={technology?technology.body[4].primary.tech_tittle[0].text:"OUR TECHNOLOGY EXPERTISE"}
              animateName="slide-right"
              animateDuration="1200"
            />
            {/* Section Title End */}
          </div>

          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle itemImage={technology?technology.body[4].primary.liferay.url:image1} title={technology?technology.body[4].primary.text1[0].text:"Liferay"} itemLink="/Liferay" />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle itemImage={technology?technology.body[4].primary.bigdata.url:image2} title={technology?technology.body[4].primary.text2[0].text:"React"} itemLink="/Reacttech" />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle itemImage={technology?technology.body[4].primary.alfresco.url:image3} title={technology?technology.body[4].primary.text3[0].text:"Angular"} itemLink="/Angular" />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle itemImage={technology?technology.body[4].primary.joomla.url:image4} title={technology?technology.body[4].primary.text4[0].text:"Java"} itemLink="/Java" />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <DegreeSingle
              itemImage={technology?technology.body[4].primary.php.url:image5}
              title={technology?technology.body[4].primary.text5[0].text:".Net"}
              itemLink="/dotNet"
            />
          </div>
        </div>
    
      </div>
      
    </div>
  );
};

export default Courses;
