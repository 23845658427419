import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/web1-1.jpg";
import img2 from "../../assets/img/serviceinsidepics/web1-2.jpg";
import img3 from "../../assets/img/serviceinsidepics/web1-3.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";

import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Management = () => {
  const [document] = usePrismicDocumentByUID(
    "services_webportal",
    "businessmanagementid"
  );

  const slice5 = document && document?.data;

  

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${
      slice5 ? slice5 ?.body[0].primary.bgimg1.url :"/B1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",

    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/black1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice5
                      ? slice5?.body[0].primary.tittle[0].text
                      : " Business Process Management"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5
                      ? slice5?.body[0].primary.text1[0].text
                      : "A business process comprises a series or network of                    value-added activities, performed by their relevant roles or                    collaborators, to purposefully achieve the common business                    goal. These processes are critical to any organization, as                    they can generate revenue and often represent a significant                    proportion of costs. As a managerial approach, BPM considers                    processes to be strategic assets of an organization that                    must be understood, managed, and improved to deliver value                    added products and services to clients."}
                    <br />
                    <br />
                    {slice5
                      ? slice5?.body[0].primary.text2[0].text
                      : " The Business processes are driven by events and change                    frequently during the life cycle of a process. The state of                    services should be managed for proper integration during the                    execution of a business process. BPM goes a step further by                    stating that this approach can be supported, or enabled,                    through technology to ensure the viability of the managerial                    approach in times of stress and change."}                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--70"
        style={{ background: "#6B5B95" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={
                  slice5
                    ? slice5?.body[1].items[0].tittle[0].text
                    :"BPM life-cycle"}
                subtitle={
                  slice5
                    ? slice5?.body[1].items[0].subtittle[0].text
                    :"Business process management activities can be grouped into six categories: vision, design, modeling, execution, monitoring, and optimization."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--60">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={
                  slice5
                    ? slice5?.body[1].items[1].tittle[0].text
                    :"BPM Technology"}
                subtitle={
                  slice5
                    ? slice5?.body[1].items[1].subtittle[0].text
                    :"Business process management (BPM) tools can be used to implement business processes through the orchestration of activities between people and systems."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--60"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={
                  slice5
                    ? slice5?.body[1].items[2].tittle[0].text
                    :"Execution"}
                subtitle={slice5
                  ? slice5?.body[1].items[2].subtittle[0].text
                  :"A combination of software and human intervention is used to execute the processes as per the designed flow."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-6 mbl-mb--50"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Optimization"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"Process optimization includes retrieving process performance information from modeling or monitoring phase; identifying the potential or actual bottlenecks and the potential opportunities for cost savings or other improvements; and then, applying those enhancements in the design of the process. Overall, this creates greater business value."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--40">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Design"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"The Design Process consists of both the identification of existing processes and the design of 'to-be' processes. The key focus include representation of the process flow, the actors & role within it, alerts & notifications, escalations, SOP, SLA, and task hand-over mechanisms."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--60"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Modeling"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"Modeling takes the theoretical design and introduces combinations of variables (e.g., changes in rent or materials costs, which determine how the process might operate under different circumstances)."}
              />
            </div>
          </div>
        </div>
      </div>

      <LifeSpan />
    </React.Fragment>
  );
};

export default Management;
