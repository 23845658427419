import React, { useContext } from 'react';
import BlogPartThree from '../../components/Blog/HomeBlogSectionThree';
import SectionTitle from '../../components/Common/SectionTitle';
import { sliceBanner } from '../../components/Context/Context';

const Blog = () => {
 const sliceBannesr=useContext(sliceBanner);
    return (
        <React.Fragment>
            <div id="rs-blog" className="rs-blog style2 pt-20  md-pt-30 md-pb-1">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title text-center mb-20 md-mb-30 text-center"
                        titleClass="title mb-10 orange-color"
                        title={sliceBannesr?sliceBannesr.body[7].primary.casetitle[0].text:"Case Studies"}
                    />
                    <BlogPartThree  />
                </div>
            </div>
        </React.Fragment>
    );

}

export default Blog;