import SectionTitle from "../../components/Common/SectionTitle";
import SingleService from "../../components/Service/SingleService";
import dummy1 from '../../assets/img/dummy/1.png';
import dummy2 from '../../assets/img/dummy/2.png';
import dummy3 from '../../assets/img/dummy/3.png';
import AOS from 'aos';



import { useEffect } from "react";

 
const Service = (props:any) => {
const {industry}=props ;


    

  useEffect(() => {
    AOS.init();
})
 
  return (
    <div className="rs-services style2 pt-10 pb-5 md-pt-10">
      <div data-aos="zoom-out" data-aos-duration="1500" data-aos-delay="300">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-20 text-center"
          titleClass="title extra-bold mb-14 sm-mb-10 orange-color"
          title={industry?industry.body[2].primary.tittle[0].text:"OUR INDUSTRY EXPERTISE"}
        />
       
        <div className="row">
          <div className="col-lg-4 md-mb-30">
            <SingleService
             linkpage='/Ecommerce'
              Icon={industry?industry.body[2].primary.indusimg1.url:dummy1}
              Title={industry?industry.body[2].primary.text1[0].text:"E-commerce"}
              Desc={industry?industry.body[2].primary.dec1[0].text:"E-commerce is essentially doing any method business electronically."}
              
            />
          </div>
          <div className="col-lg-4 md-mb-30">
            <SingleService
            linkpage='/Hrms'
              Icon={industry?industry.body[2].primary.indusimg2.url:dummy2}
              Title={industry?industry.body[2].primary.text2[0].text:"HRMS"}
              Desc={industry?industry.body[2].primary.dec2[0].text:"The HR function consists of tracking existing employee data, which traditionally includes personal histories, skills, capabilities, accomplishments, and salary."}
            />
          </div>
          <div className="col-lg-4 md-mb-30">
            <SingleService
             linkpage='/Education'
              Icon={industry?industry.body[2].primary.indusimg3.url:dummy3}
              Title={industry?industry.body[2].primary.text3[0].text:"Education"}
              Desc={industry?industry.body[2].primary.dec3[0].text:"Learning the acquisition of knowledge, skills, values by Smarter Education Systems."}
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
