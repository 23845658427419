import React from "react";
import { Link } from "react-router-dom";

import defaultBG from "../../../assets/img/bg/footer-bg.png";
import normalLogo from '../../../assets/img/HomeLogo/normalLogo.png';
import darkLogo from "../../../assets/img/HomeLogo/darklogo.png";

const FooterStyleTwo = (props: any) => {
  const { footerClass, footerTopClass, footerLogo, footerBG, footerColor } =
    props;

  const footerBGStyle = {
    backgroundImage: `url(${footerBG})`,
    backgroundColor: `url(${footerColor})`,
  };
  const defaultBGStyle = {
    backgroundImage: `url(${defaultBG})`,
    backgroundColor: "#273c66",
  };

  return (
    <footer
      id="rs-footer"
      className={footerClass ? footerClass : "rs-footer"}
      style={footerBG && footerColor ? footerBGStyle : defaultBGStyle}
    >
      <div className={footerTopClass ? footerTopClass : "footer-top"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">Products</h4>
              <ul className="site-map">
                <li>
                  <Link to="/Ecommerce">E-commerce</Link>
                </li>
                <li>
                  <Link to="/Travel">Travel</Link>
                </li>
                <li>
                  <Link to="/Education">Educational</Link>
                </li>
                <li>
                  <Link to="/Hrms">HRMS</Link>
                </li>
                <li>
                  <Link to="/HealthCare">HealthCare</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">Company</h4>
              <ul className="site-map">
                <li>
                  <Link to="/Company">About Us</Link>
                </li>
                <li>
                  <Link to="/course-categories">News</Link>
                </li>
                <li>
                  <Link to="/Clientele">Clientele</Link>
                </li>
                <li>
                  <Link to="/">Testimonials</Link>
                </li>
                <li>
                  <Link to="/Careers">Careers</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">Support</h4>
              <ul className="site-map">
                <li>
                  <Link to="/">Product Support</Link>
                </li>
                <li>
                  <Link to="/CaseStudies">Knowledge Base</Link>
                </li>
                <li>
                  <Link to="/Portal">FAQs</Link>
                </li>
                <li>
                  <Link to="/Contact">Request a Quote</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
              <h4 className="widget-title">GET IN TOUCH</h4>
              <ul className="address-widget address2">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                  377 Green Lane, Small Heath, Birmingham B9 5PP, UK.
                  </div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <a href="tel:(123)-456-7890">+44 7865671970</a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <a href="mailto:infoname@gmail.com">sales@esquareinfo.uk</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-4 md-mb-20">
              <div className="footer-logo mbl-ml-70">
                <Link to="/Header">
                  <img src={darkLogo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 md-mb-20">
              <div className="copyright text-center md-text-left  mbl-ml-10">
                <p>© 2023- Esquare Info Ltd All Rights Reserved </p>
              </div>
            </div>
            <div className="col-lg-3 text-right md-text-left mbl-ml-70">
              <ul className="footer-social">
                <li>
                  <a href="https://www.facebook.com/EsquareInfoSolutions/">
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/esquareinfo">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                
                <li>
                  <a href="https://wa.me/+447865671970">
                    <i className="fa fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                 <a href="https://www.linkedin.com/company/esquare-info-solutions/">
                    <i className="fa fa-linkedin"></i>
                    </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterStyleTwo;
