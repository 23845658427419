import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/web2-3.png";
import img2 from "../../assets/img/serviceinsidepics/bns1.png";
import img3 from "../../assets/img/serviceinsidepics/online3.png";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Portal = () => {
  const [document] = usePrismicDocumentByUID(
    "services_webportal",
    "solutionportalid"
  );
  const slice5 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${
      slice5 ? slice5?.body[0].primary.bgimg1.url : "/P1.jfif"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "right",

    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${
      slice5 ? slice5?.body[2].primary.bg_img.url : "/black1.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${
      slice5 ? slice5?.body[3].primary.listimage.url : "/web3.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice5 ? slice5?.body[0].primary.tittle[0].text : "Portal"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    {slice5
                      ? slice5?.body[0].primary.text1[0].text
                      : "Portal's provides a single access point to content and                    applications, while delivering differentiated, personalized                    experiences for each user. Modern portals have added                    multiple features that make them the best choice for a wide                    array of web applications.                                        Portal platforms make it easy for users to build web pages                    and websites by assembling portlets or gadgets onto a portal                    page. Portal websites combine a theme (header/footer and                    common look and feel), a set of pages, navigation (menu bar,                    etc.), and a set of portlets and gadgets. Administrators can                    build pages without coding by reusing existing portlets and                    gadgets."}
                    <br />
                    {slice5
                      ? slice5?.body[0].primary.text2[0].text
                      : "A web portal is most often one specially designed Web                    page that brings information together from diverse sources                    in a uniform way. Usually, each information source gets its                    dedicated area on the page for displaying information (a                    portlet); often, the user can configure which ones to                    display."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#6B5B95" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5 ? slice5?.body[1].items[0].cardimages.url : img1}
                title={
                  slice5
                    ? slice5?.body[1].items[0].tittle[0].text
                    : "Listen and Respond"
                }
                subtitle={
                  slice5
                    ? slice5?.body[1].items[0].subtittle[0].text
                    : "In this phase, an organization can listen to what stakeholders are saying on social media and provide real-time response, capture patterns and unusual spikes helping the organization take corrective and preventive actions."
                }
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <CardSingle
                itemClass="courses-item"
                image={slice5 ? slice5?.body[1].items[1].cardimages.url : img2}
                title={
                  slice5
                    ? slice5?.body[1].items[1].tittle[0].text
                    : "Engage and Connect"
                }
                subtitle={
                  slice5
                    ? slice5?.body[1].items[1].subtittle[0].text
                    : "With this integrated insight, you can now create customized campaigns for a targeted audience, modify existing products based on what people want and gather enough inputs to create new leads"
                }
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5 ? slice5?.body[1].items[2].cardimages.url : img3}
                title={
                  slice5
                    ? slice5?.body[1].items[2].tittle[0].text
                    : "Analyze and Insight"
                }
                subtitle={
                  slice5
                    ? slice5?.body[1].items[2].subtittle[0].text
                    : "The social data is integrated with your internal systems like CRM, POS, Inventory etc and with external platforms like LinkedIn and Klout to provide actionable business insight and identify new opportunities."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-9 mbl-mb--70"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={
                  slice5
                    ? slice5?.body[2].items[0].tittle[0].text
                    : "Build websites"
                }
                subtitle={
                  slice5
                    ? slice5?.body[2].items[0].subtittle[0].text
                    : "Portals make it easy to build websites that show different content depending on whether or not the person is logged in."
                }
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--140">
              <TextSingle
                itemClass="courses-item"
                title={
                  slice5
                    ? slice5?.body[2].items[1].tittle[0].text
                    : "Development of websites"
                }
                subtitle={
                  slice5
                    ? slice5?.body[2].items[1].subtittle[0].text
                    : "Portals additionally simplify the development of websites that display different data depending on a user's role. In addition, portals allow end-users to define pages, and add content to their pages using predefined portlets or gadgets."
                }
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--100"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={
                  slice5 ? slice5?.body[2].items[2].tittle[0].text : "Available"
                }
                subtitle={
                  slice5
                    ? slice5?.body[2].items[2].subtittle[0].text
                    : "Once it is developed, a website may need to be made available in multiple languages and from multiple platforms (e.g., smart phones, tablets). The portal provides a method to simplify the development and management of pages for each type of end-user."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-110"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-220"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={
                  slice5
                    ? slice5?.body[3].primary.tittle[0].text
                    : "At Esquare we deliver to the promise of Simplifying Open Source, where we identify the requirement from the client and deliver a simplified solution. A few solutions that we offer from a strong team of experienced resources are."
                }
                listClass=""
                portalList=""
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                list1={
                  slice5
                    ? slice5?.body[3].items[0].lists[0].text
                    : "News portals."
                }
                list2={
                  slice5
                    ? slice5?.body[3].items[1].lists[0].text
                    : "Jobs Portals."
                }
                list3={
                  slice5
                    ? slice5?.body[3].items[2].lists[0].text
                    : "Social Networking portal."
                }
                list4={
                  slice5
                    ? slice5?.body[3].items[3].lists[0].text
                    : "  Corporate web portals."
                }
                list5={
                  slice5
                    ? slice5?.body[3].items[4].lists[0].text
                    : "  Personal portals."
                }
                list6={
                  slice5
                    ? slice5?.body[3].items[5].lists[0].text
                    : "     Search portals."
                }
                list7={
                  slice5
                    ? slice5?.body[3].items[6].lists[0].text
                    : "Domain-specific portals."
                }
                list8={
                  slice5
                    ? slice5?.body[3].items[7].lists[0].text
                    : "Knowledge Management Portal."
                }
                list9={
                  slice5
                    ? slice5?.body[3].items[8].lists[0].text
                    : " Intranet Portal."
                }
                list10={
                  slice5
                    ? slice5?.body[3].items[9].lists[0].text
                    : "E-commerce Portal."
                }
                list11={
                  slice5
                    ? slice5?.body[3].items[10].lists[0].text
                    : " Customer – Supplier Portal."
                }
                list12={
                  slice5
                    ? slice5?.body[3].items[11].lists[0].text
                    : "Wiki Portal."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Portal;
