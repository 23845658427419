import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "./Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/train1.jpg";
import img2 from "../../assets/img/serviceinsidepics/train2.jpg";
import img3 from "../../assets/img/serviceinsidepics/train3.jpg";

import TextSingle from "./TextCard/TextSingle";
import NormalText from "./NormalText/NormalText";
import LifeSpan from "./lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const CorparateTrain = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","corpid");
  const slice5 = document && document?.data;
  console.log(slice5);


  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/train.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/black1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.bgimg3.url:"/train2.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 ">
                <div className="sec-title3 mb-10 pt-100 ">
                  <h2
                    className="title white-color  Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice5?slice5?.body[0].primary.tittle[0].text:"Training"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    {slice5?slice5?.body[0].primary.text1[0].text: "Esquare provides an opportunity to organizations and                    Individuals to develop their skill sets.                     As it's the most important part for an                    individual and company to stay ahead of the competition."}
                    <br /> <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:" Esquare offers training solutions in three different                    categories, Corporate trainings; (ELTP) Entry Level Training                    Program for fresher's & new hires; and Open House Training                    Program."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#00758F" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Corporate Training"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"We understand that one size doesn't fit all hence we provide different training solutions to our clients based on their requirement. Improving the performance of the individuals and groups of employees in the organizational settings. It is an organized activity for increasing the knowledge and skills of the employees."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Liferay"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Our Training methodology is based on role based approach, which helps developers, architect and project managers to get hands-on experience on Liferay Portal administration, System administration, Plugin development and Extension development."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Web Technologies"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"This will help our participants understand the subject effectively and swiftly in addition we allow the customization of the training to align with your project needs with the flexibility and practicality for immediate implementation. This is a required training for individuals who wish to become Liferay Portal expert."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-10 md-pt-10 md-pb-2"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title=""
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"At our company, we recognize that every client has unique training needs. That's why we offer customized solutions to help improve the performance of individuals and teams within an organization. Our training programs are designed to enhance the knowledge and skills of employees, ultimately leading to increased productivity and success."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--70">
              <TextSingle
                itemClass="courses-item"
                title=""
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"At our company, we use a role-based approach to training that allows professionals such as developers, architects, and project managers to gain practical experience in Liferay Portal administration, system administration, plugin development, and extension development."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--100"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title=""
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"Our training programs are designed to provide a comprehensive understanding of the subject matter, ensuring that participants are able to grasp the material quickly and effectively. In addition, we offer customized training options that can be tailored to align with specific project needs and provide the practical knowledge required for immediate implementation."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-90 md-pt-10 md-pb-300"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-100"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Web development"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice5?slice5?.body[3].primary.text1[0].text:"We don't satisfy your need but exceed your expectations by providing resources who can manage your projects and build your resource capabilities by mentoring and providing solutions which are required to meet your requirements. In turn increasing your profitability and helping you succeed."}
                subtitle2={slice5?slice5?.body[3].primary.text2[0].text:"Design relates to creating something that will resonate with the intended user on several levels, including emotional, social, cultural, physical, and cognitive. Design planning includes: Problem framing and hypothesis development. Research (contextually based using both quantitative and qualitative methods)."}
                subtitle3={slice5?slice5?.body[3].primary.text3[0].text:"Designing training programme is an important component of systematic approach to training. ... Any programme designed for 'training of trainers (TOT)' should have a component to enhance the instructional skills of the participants and another to impart knowledge and enhance skills in the subject matter."}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default CorparateTrain;
