import { usePrismicDocumentByUID } from "@prismicio/react";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import mainImage from "../../../assets/img/blog/style2/8.jpg";


const CloudService = () => {

  const [document] = usePrismicDocumentByUID("casestudiesitems","cloudserviceid");
  const slice7 = document && document?.data;


  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);

  
const bg = {
  backgroundImage:  `url(${
    slice7 ? slice7?.body[0].primary.bgimg.url :"/cloud.jpg"})`,
  backgroundSize: "cover",
  backgroundPosition: "right",
  height: "510px",
  backgroundRepeat: " no-repeat",
};

const button1 = {
  backgroundColor: "#369dd2",
  color: "#FFFFFF",
  border: "none",
  fontWeight: "bold",
  padding: "7px 25px",
  borderRadius: "2px",
  marginLeft: "24px",
};
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "140px" }}
                  >
                    {slice7
                      ? slice7?.body[0].primary.tittle[0].text
                      : "Case Study"}
                    <p style={{ fontSize: "20px" }}>{slice7
                        ? slice7?.body[0].primary.text[0].text
                        : "Cloud Services Platform"}</p>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-20">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <img
                  src={slice7 ? slice7?.body[1].primary.leftimg.url :mainImage}
                  alt="Main Image"
                  style={{ borderRadius: "30px", width: "100%" }}
                />
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle1[0].text
                  :"Business Need"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[0].list[0].text
                  : "Client wants to build a robust platform for offering cloud                services targeted at enterprise customers. Apart from investing                in data centers, secure networks, they needed a rock solid                platform to support and sustain an evolving eco system, enable                interactive collaboration with the user community, and enhance                operational efficiencies."}
              </p>

              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle2[0].text
                  :"Challenges"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[1].list[0].text
                  : "The existing portal had limited features wasn't flexible and                extendable to address the client's business needs mentioned                below."}
                <li>
                {slice7
                  ? slice7?.body[2].items[2].list[0].text
                  : "Business Growth – Through Sales Channel and Channel Partner."}
                </li>
                <li>{slice7
                  ? slice7?.body[2].items[3].list[0].text
                  : "Onboarding Process Facilitation."}</li>
                <li>
                {slice7
                  ? slice7?.body[2].items[4].list[0].text
                  : "Enhanced User Experience by way of Interactive Interfaces."}
                </li>
                <li>
                {slice7
                  ? slice7?.body[2].items[5].list[0].text
                  : "Internal Efficiencies, Workflow management, Automation and                  Integrations.."}
                </li>
                <li>{slice7
                  ? slice7?.body[2].items[6].list[0].text
                  : "Secure and Sustainable Platform."}</li>
              </p>

              <h2 style={{ color: "	#800000" }}> {slice7
                  ? slice7?.body[2].primary.tittle3[0].text
                  : "Key Features"}</h2>

              <li>{slice7
                  ? slice7?.body[2].items[7].list[0].text
                  : "Fully customized applications with rich user interface."}</li>
              <li>
              {slice7
                  ? slice7?.body[2].items[8].list[0].text
                  : "Enhanced User Experience by way of Self-Provisioning, Support                Processes and Life Cycle Management."}
              </li>
              <li>
              {slice7
                  ? slice7?.body[2].items[9].list[0].text
                  : " Dynamic security with user authentication at various levels."}
              </li>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
                 {slice7
              ? slice7?.body[3].primary.text[0].text
              :"Click Here For Full Brochure"}
            <button style={button1}>Download</button>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CloudService;
