import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/comcover.jpg";
import img2 from "../../assets/img/serviceinsidepics/guide.jpg";
import img3 from "../../assets/img/serviceinsidepics/international.jpg";
import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Travel = () => {

  const [document] = usePrismicDocumentByUID("services_webportal","travelid");
  const slice5 = document && document?.data;
  console.log(slice5);



  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);



  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/travel.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
    height: "530px",
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/web2.jfif"})`,
    backgroundSize: "cover",
    height: "250px",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.bgimg3.url:"/bns2.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "120px" }}
                  >
                    {slice5?slice5?.body[0].primary.tittle[0].text:" Travel Solutions"}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#4f82c2" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Comcover International SOS"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"International SOS is the Commonwealth's contracted overseas medical and travel assistance (OMTA) provider for employees of Comcover Fund Members."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Guide to International SOS services"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Travel bookers, travel managers and travellers are encouraged to access the resources provided by International SOS prior to undertaking travel."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"International SOS App"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"A downloadable application for compatible iOS, Android, Windows and Blackberry mobile devices, which includes contacts for help, advice and support while you are overseas."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-580"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <h3 style={{ color: "white" }}>{slice5?slice5?.body[2].primary.tittle[0].text:"Special Assistance"}</h3>
            <div
              className="col-lg-4 col-md-6 mb-30 md-mt--40"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Travelling with Disability"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"In compliance with regulations and as part of our commitment to serve all our guests fairly and without discrimination."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 md-mt--120">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Destination Reviews"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Travelling to a new destination is always fun. Explore your destination even before you travel by reading first-hand reviews of hotels, restaurants, attractions and activities."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 md-mt--80"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Customs, Currency and Airport Tax"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"There are restrictions on goods that you can bring into and take out of the country including animal and plant material, food, wildlife, medicinal products, firearms etc."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-140"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-100"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Tips and Tools"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice5?slice5?.body[3].primary.text1[0].text:"Let us help you plan your trip better. Get to know the latest weather at your destination, browse through visitor reviews and read our travel giudelines to ensure you know it all before you go."}
                subtitle2={slice5?slice5?.body[3].primary.text2[0].text:"Though the popularity of this platform burned out fairly quickly, its loss could still impact some marketing factors for local businesses."}
                subtitle3={slice5?slice5?.body[3].primary.text3[0].text:" Automation is working its way into so many industries and business functions — including podcasting. If you have a podcast and are looking to make the posting process easier."}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Travel;
