import { usePrismicDocumentByUID } from "@prismicio/react";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import mainImage from "../../../assets/img/blog/style2/5.png";

const Enterprisehealth = (props:any) => {


  const [document] = usePrismicDocumentByUID("casestudiesitems","healthid");
  const slice7 = document && document?.data;
console.log(slice7?.body);

  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);

  
const bg = {
  backgroundImage: `url(${
    slice7 ? slice7?.body[0].primary.bgimg.url :"/enterprisehealth.jfif"})`,
  backgroundSize: "cover",
  backgroundPosition: "right",
  height: "550px",
  backgroundRepeat: " no-repeat",
};

const button1 = {
  backgroundColor: "#369dd2",
  color: "#FFFFFF",
  border: "none",
  fontWeight: "bold",
  padding: "7px 25px",
  borderRadius: "2px",
  marginLeft: "24px",
};

  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "240px" }}
                  >
                    {slice7
                      ? slice7?.body[0].primary.tittle[0].text
                      : "Case Study"}
                    <p style={{ fontSize: "20px" }}>
                    {slice7
                        ? slice7?.body[0].primary.text[0].text
                        : "Enterprise Health Care Portal"}
                    </p>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-20">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <img
                  src={slice7 ? slice7?.body[1].primary.leftimg.url :mainImage}
                  alt="Main Image"
                  style={{ borderRadius: "30px", width: "100%" }}
                />
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle1[0].text
                  :"Business Need"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[0].list[0].text
                  : "Client wants to build An Enterprise HealthCare portal for                maintaining the Medicaid program of different states in the USA.                This system should allow the poor and senior citizens to enroll                as members, it allows the service provider (doctors, hospitals,                lab technicians, ambulance services, etc) to enroll."}
              </p>

              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle2[0].text
                  :"Challenges"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[1].list[0].text
                  : "The existing portal had limited features wasn't flexible and               extendable to address the client's business needs mentioned                below. The system is should handle the use case base of 30                million for states like Florida and New York, with few thousands                of Providers, Over a million claims should be processed every                week Internal Efficiencies, Workflow management, Automation and                Integrations. Secure and Sustainable Platform."}
              </p>

              <h2 style={{ color: "	#800000" }}> {slice7
                  ? slice7?.body[2].primary.tittle3[0].text
                  : "Key Features"}</h2>

              <li> {slice7
                  ? slice7?.body[2].items[2].list[0].text
                  : "Fully customized application with rich user interface."}</li>
              <li>{slice7
                ? slice7?.body[2].items[3].list[0].text
                : "Dynamic securities for different roles."}</li>
              <li>{slice7
                ? slice7?.body[2].items[4].list[0].text
                : "Flexible and Robust Contact Management."}</li>
              <li>{slice7
                ? slice7?.body[2].items[5].list[0].text
                : "Robust Provider Registration, Application and Inquiry."}</li>
              <li>{slice7
                ? slice7?.body[2].items[6].list[0].text
                : "Ease of maintenance of Members and Providers."}</li>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
           {slice7
              ? slice7?.body[3].primary.text[0].text
              :"Click Here For Full Brochure"}
            <button style={button1}>Download</button>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Enterprisehealth;
