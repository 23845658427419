import React, { createContext } from "react";
import CTA from "./CtaSection";
import Blog from "./BlogSection";
import About from "./AboutSection";
import Courses from "./CourseSection";
import Service from "./ServiceSection";

import Testimonial from "./TestimonialSection";
import Brand from "../../components/Common/Brand";
import ScrollToTop from "../../components/Common/ScrollTop";
import BannerStyleThree from "../../components/Banner/BannerStyleThree";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { sliceBanner } from "../../components/Context/Context";

const HomeSixMain = () => {
  const [document] = useAllPrismicDocumentsByType("home-page");
  const slice = document && document[0]?.data;
  console.log(slice);
   const BlogSlice=createContext<any>(null);

  return (
    <React.Fragment>
      {/* Banner-area-start */}
      <BannerStyleThree slideslice={slice} />
      {/* Banner-area-end  */}

      {/* About-area-start */}
      <About  ourservice={slice}/>
      {/*     About-area-end */}

      {/* Service-area-start */}
      <Service industry={slice}/>
      {/* Service-area-end */}

      {/* CTA-area-start */}
      <CTA ctaslice={slice}/>
      {/* CTA-area-end */}

      {/* Courses-area-start */}
      <Courses technology={slice}/>
      {/* Courses-area-end */}

      {/* brand-area-start */}
      <Brand Brandslice={slice} brandClass="rs-partner pb-100 md-pb-80" />
      {/* brand-area-end */}

      {/* Testimonial-area-start */}
      <Testimonial Clientsays={slice}/>
      {/* Testimonial-area-end */}

      {/* blog-area-start */}
      <sliceBanner.Provider value={slice}>
      <Blog />
      </sliceBanner.Provider>
      {/* blog-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeSixMain;
