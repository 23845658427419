import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/online1.png";
import img2 from "../../assets/img/serviceinsidepics/online2.png";
import img3 from "../../assets/img/serviceinsidepics/online3.png";
import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const OnlineMark = () => {

  const [document] = usePrismicDocumentByUID("services_webportal","onlineid");
  const slice5 = document && document?.data;



  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/online1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/web2.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.bgimg3.url:"/online2.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 pt-60">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice5?slice5?.body[0].primary.tittle[0].text:"Online Marketing Services"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:"Having a website that ranks low in a search engine can't                    make your business grow. You are missing the boat if you                    think that just owning a website is more than enough to                    reach your targeted audience."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"Benefits of doing business online. You don't have to run                    your entire business over the internet to benefit from                    online business opportunities. Small businesses might only                    need an email address to communicate with their clients,                    customers and suppliers electronically. ... improved client                    service through greater flexibility."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--70"
        style={{ background: " #B89B72" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Retail"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"We help global retailers deliver superior customer experience with capabilities in commerce, in-store tools, analytics and much more. Read more to rewrite retail with technology."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--40">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Manufacturing"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"We have helped large manufacturing organisations gain agility and flexibility with clear and achievable goals consistently."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--80"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Health Care"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"We help health care industries to sell more products and services providing solutions to hospitals & other healthcare organisations."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-20 mbl-mb--40 mbl-mb--110"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Content Marketing"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"where marketers try to create valuable media and content and distribute it to potential future customers."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--130">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Social Media Marketing"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"where you use one or several social media channels to engage with customers, build relationships and then send them to your products and services."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--100"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Affiliate Marketing"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"Is a kind of referral marketing, where you share profits with fellow marketers, in exchange for promoting each other’s products."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-180"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Effective online marketing"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice5?slice5?.body[3].primary.text1[0].text:"Effective online marketing will put your products or services in front of the people who really need them. Even if you don’t sell anything directly online, your business can still benefit from an internet marketing strategy that tells the world’s biggest marketplace all about whom you are and what you’ve got to offer."}
                subtitle2={slice5?slice5?.body[3].primary.text2[0].text:"As a n open marketplace, Google Play puts you in control of how you sell your products. You can publish whenever you want, as often as you want, and to the customers you want. You can distribute broadly to all markets and devices or focus on specific segments, devices, or ranges of hardware capabilities."}
               
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default OnlineMark;
