import React, { useEffect } from "react";

import img1 from "../../assets/img/technologyinsidepic/main.jpg";
import img3 from "../../assets/img/technologyinsidepic/pci.jpg";
import img2 from "../../assets/img/technologyinsidepic/appl.jpg";
import Aos from "aos";
import CardSingle from "../Services/Card/CardSingle";
import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Drupal = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","drupalid");
  const slice5 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/DrupalBg1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/DrupalBg3.gif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundSize: "cover",
    backgroundPosition: "cover",
    backgroundImage: `url(${slice5?slice5?.body[3].primary.listimage.url:"/DrupalBg2.png"})`,
    backgroundRepeat: " no-repeat",
    
  };
  const whiteColor = {
    color: "white",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60  " style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                     {slice5?slice5?.body[0].primary.tittle[0].text:"Drupal"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:"Drupal is open source software maintained and developed by a                    community of 630,000+ users and developers. It's distributed                    under the terms of the GNU General Public License (or'GPL'), which means anyone is free to download it and share                    it with others. This open development model means that                    people are constantly working to make sure.          Drupal is a cutting-edge platform that supports the latest                    technologies that the Web has to offer. The Drupal project's                    principles encourage modularity, standards, collaboration,                    ease-of-use, and more."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:" Drupal an open source content management system (CMS) has                    been written in PHP. Drupal is used as a back-end system for                    at least 1% of all the websites across the global. Drupal                    has emerged extensively in last couple of years due to it's                    capability to development robust and scalable web                    applications.                              Drupal is very popular to be used as knowledge management                    system."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#4f82c2" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Maintenance Led Modernization"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text: "Improving business agility through application and infrastructure modernisation.unique approach to “de-risk” the entire transformation journey of the legacy systems. In order to transform the systems it is very important to transition the support of the systems and optimize the same first."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Application business process"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Business process management is a discipline in operations management in which people use various methods to discover, model, analyze, measure, improve, optimize, and automate business processes."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--40"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                textClass={whiteColor}
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"PCI Compliance at Vista"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"Vulnerability Assessment and Penetration Testing.Design from scratch, customise a template, or upload own logo or complete design. Money Back Guaranteed. 2K Products 2 Lac Designs. No Hidden Charges. COD Available. No Minimum Orders."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-4 "
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"BPM life-cycle"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"Business process management activities can be grouped into six categories: vision, design, modeling, execution, monitoring, and optimization."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--130">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Design"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"The Design Process consists of both the identification of existing processes and the design of to-be processes. The key focus include representation of the process flow, the actors & role within it, alerts & notifications, escalations, SOP, SLA, and task hand-over mechanisms."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--70"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Modeling"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"Modeling takes the theoretical design and introduces combinations of variables (e.g., changes in rent or materials costs, which determine how the process might operate under different circumstances)."}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-30 pb-80 md-pt-10 md-pb-300"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Key Features of Drupal:"}
                portalList="portal-none"
                ecommerce="ecommerce-none"
                twoList="twoList"
                cloudList="cloud-none"
                listtwo=""
                listthree="listthree-none"
                list1={slice5?slice5?.body[3].items[0].lists[0].text:"Drupal is Open source CMS so, it is most cost effective website CMS Solutions."}
                list2={slice5?slice5?.body[3].items[1].lists[0].text:"Drupal come with great modular architecture, new feature and functionality can be added or removed by custom Drupal development."}
                list3={slice5?slice5?.body[3].items[2].lists[0].text:"For better performance it supported caching and feature throttling."}
                list4={slice5?slice5?.body[3].items[3].lists[0].text:"Supported for multi-user content creation and editing."}
                list5={slice5?slice5?.body[3].items[4].lists[0].text:"It supported by large Expert Drupal programmers community so, new update and features are available at regular intervals."}
                list6={slice5?slice5?.body[3].items[5].lists[0].text:"Advance search functionality and SEO friendly URLs."}
                list18={slice5?slice5?.body[3].items[6].lists[0].text:"Variety of Module and themes templates for enhances Drupal website functionality like forum, discussion board, shopping cart, podcasting, photo gallery etc."}
                list19={slice5?slice5?.body[3].items[7].lists[0].text:"Esquare Info Solutions acquires a strong team of drupal developers globally to manage the CMS requirements in building complex applications."}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Drupal;
