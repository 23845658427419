import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomeSixMain from "./pages/home-6/HomeSixMain";
import ContactFour from "./pages/contact-4";

import WebPortal from "./pages/Services/WebPortal";
import WebDesign from "./pages/Services/WebDesign";
import MobileApp from "./pages/Services/MobileApp";
import CorparateTrain from "./pages/Services/CorparateTrain";
import WebHosting from "./pages/Services/WebHosting";
import OnlineMark from "./pages/Services/OnlineMark";
import Business from "./pages/Services/Business";
import Portal from "./pages/Solutions/Portal";
import Cloud from "./pages/Solutions/Clould";
import Content from "./pages/Solutions/Content";
import Ecommerce from "./pages/Solutions/Ecommerce";
import Management from "./pages/Solutions/Management";
import Bigdata from "./pages/Technologies/Bigdata";
import Android from "./pages/Technologies/Android";
import Magento from "./pages/Technologies/Magento";
import Brochure from "./pages/ShowCase/Brochure";
import Presentation from "./pages/ShowCase/Presentation";
import Clientele from "./pages/ShowCase/Clientele";
import CaseStudies from "./pages/ShowCase/Casestudy/Casestudies";
import Companyportal from "./pages/ShowCase/Casestudy/Companyportal";
import Enterprisehealth from "./pages/ShowCase/Casestudy/Enterprisehealth";
import Security from "./pages/ShowCase/Casestudy/Security";
import Budgetplanning from "./pages/ShowCase/Casestudy/Budgetplanning";
import Meetingscheduler from "./pages/ShowCase/Casestudy/Meetingschedule";
import TripPlanner from "./pages/ShowCase/Casestudy/Tripplanner";
import WeatherApp from "./pages/ShowCase/Casestudy/Weather";
import MobileApplication from "./pages/ShowCase/Casestudy/Mobileapplication";
import RoadAccident from "./pages/ShowCase/Casestudy/Roadaccident";
import CloudService from "./pages/ShowCase/Casestudy/Cloudservice";
import MultiLingual from "./pages/ShowCase/Casestudy/Multilingual";
import Liferay from "./pages/Technologies/Liferay";
import Alfresco from "./pages/Technologies/Alfresco";
import Drupal from "./pages/Technologies/Drupal";
import Joomla from "./pages/Technologies/Joomla";
import PHPFrame from "./pages/Technologies/PhpFrame";
import Company from "./pages/AboutUs/Company";
import Outsource from "./pages/AboutUs/Outsource";
import MarketingPartner from "./pages/AboutUs/Marketingpartner";
import BecomePartner from "./pages/AboutUs/Becomepartner";
import Careers from "./pages/Careers/Careers";
import Travel from "./pages/Travel/Travel";
import Hrms from "./pages/HRMS/Hrms";
import HealthCare from "./pages/HealthCare/HealthCare";
import Education from "./pages/Education/Education";
import Testimonial from "./pages/home-6/TestimonialSection";
import Reacttech from "./pages/Technologies/React";
import Angular from "./pages/Technologies/Angular";
import Java from "./pages/Technologies/Java";
import DotNet from "./pages/Technologies/dotNet";
import SpringBoot from "./pages/Technologies/SpringBoot";



function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomeSixMain />}></Route>
        <Route path="/Testimonial" element={<Testimonial />}></Route>
        <Route path="/Contact" element={<ContactFour />}></Route>
        <Route path="/Company" element={<Company />}></Route>
        <Route path="/Outsource" element={<Outsource />}></Route>
        <Route path="/MarketingPartner" element={<MarketingPartner />}></Route>
        <Route path="/BecomePartner" element={<BecomePartner />}></Route>
   

        <Route path="/WebPortal" element={<WebPortal />}></Route>
        <Route path="/WebDesign" element={<WebDesign />}></Route>
        <Route path="/MobileApp" element={<MobileApp />}></Route>
        <Route path="/CorparateTrain" element={<CorparateTrain />}></Route>
        <Route path="/WebHosting" element={<WebHosting />}></Route>
        <Route path="/OnlineMark" element={<OnlineMark />}></Route>
        <Route path="/Business" element={<Business />}></Route>
        <Route path="/Portal" element={<Portal />}></Route>
        <Route path="/Cloud" element={<Cloud />}></Route>
        <Route path="/Content" element={<Content />}></Route>
        <Route path="/Ecommerce" element={<Ecommerce />}></Route>
        <Route path="/Management" element={<Management />}></Route>

        <Route path="/Liferay" element={<Liferay />}></Route>
        <Route path="/Reacttech" element={<Reacttech />}></Route>
        <Route path="/Angular" element={<Angular />}></Route>
        <Route path="/Java" element={<Java />}></Route>
        <Route path="/dotNet" element={<DotNet/>}></Route>
        <Route path="/Springboot" element={<SpringBoot/>}></Route>

        <Route path="/Bigdata" element={<Bigdata />}></Route>
        <Route path="/Android" element={<Android />}></Route>
        <Route path="/Magento" element={<Magento />}></Route>
        <Route path="/Alfresco" element={<Alfresco />}></Route>
        <Route path="/Drupal" element={<Drupal />}></Route>
        <Route path="/Joomla" element={<Joomla />}></Route>
        <Route path="/PHPFrame" element={<PHPFrame />}></Route>

        <Route path="/CaseStudies" element={<CaseStudies />}></Route>
        <Route path="/Clientele" element={<Clientele />}></Route>
        <Route path="/Brochure" element={<Brochure />}></Route>
        <Route path="/Presentation" element={<Presentation />}></Route>
        <Route path="/Companyportal" element={<Companyportal />}></Route>
        <Route path="/Enterprisehealth" element={<Enterprisehealth/>}></Route>
        <Route path="/Security" element={<Security/>}></Route>
        <Route path="/Budgetplanning" element={<Budgetplanning/>}></Route>

        <Route path="/Meetingscheduler" element={<Meetingscheduler/>}></Route>
        <Route path="/TripPlanner" element={<TripPlanner/>}></Route>
        <Route path="/WeatherApp" element={<WeatherApp/>}></Route>
        <Route path="/MobileApplication" element={<MobileApplication/>}></Route>
        <Route path="/RoadAccident" element={<RoadAccident/>}></Route>
        <Route path="/CloudService" element={<CloudService/>}></Route>
        <Route path="/MultiLingual" element={<MultiLingual/>}></Route>

        <Route path="/Careers" element={<Careers/>}></Route>
        <Route path="/Travel" element={<Travel/>}></Route>
        <Route path="/Hrms" element={<Hrms/>}></Route>
        <Route path="/HealthCare" element={<HealthCare />}></Route>
        <Route path="/Education" element={<Education/>}></Route>
      
        
      </Routes>
    </div>
  );
}

export default App;
