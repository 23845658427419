import Aos from "aos";
import React, { useEffect } from "react";
import BlogPartThree from "../../components/Blog/HomeBlogSectionThree";
import CardSingle from "../Services/Card/CardSingle";
import NormalText from "../Services/NormalText/NormalText";

const bg = {
  backgroundImage: `url("/LiferayBg1.jpeg")`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  backgroundRepeat: " no-repeat",
};
const TextBg = {
  backgroundImage: `url("/liferayimg3.jpeg")`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  backgroundRepeat: " no-repeat",
};
const Text2Bg = {
  backgroundImage: `url("/liferayBg22.jpeg")`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  backgroundRepeat: " no-repeat",
};
const bg3 = {
  backgroundImage: `url("/liferayBg33.jpeg")`,
  backgroundSize: "cover",

  backgroundRepeat: " no-repeat",
};
const ImgWidth2 = {
  width: "140px",
  height: "auto",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
};
const titleColor = {
  color: "white",
  textAlign: "center",
};

const ImgWidth = {
  maxWidth: "100%",
  height: "85px",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
};
const blackColor = {
  color: "black",
};
const cardSize = {
  display: "flex",
  justifyContent: "center",
};
const redTitle = {
  color: "red",
};

const Liferay = () => {
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60 " style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row  ">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3  mb-10">
                  <img src="/liferayimg.png"></img>
                  <h2
                    className="title white-color pt-10 mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "25px" }}
                  >
                    Single Platform
                  </h2>
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "32px" }}
                  >
                    limitless Solutions
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    Liferay named as a Leader in the 2020 Gartner Magic Quadrant
                    for Digital Experience Platforms
                  </p>
                </div>
              </div>
              <div className="col-lg-6 liferay ">
                <div className="mainDiv marginLeft">
                  <img src="/liferayright1.png" />
                  <p>Consulting Services</p>
                </div>

                <div className="mainDiv">
                  <img src="/liferayright2.png" />
                  <p>Migration and Upgradation</p>
                </div>
                <div className="mainDiv marginLeft">
                  <img src="/liferayright3.png" />
                  <p>Support and Maintenance</p>
                </div>
                <div className="mainDiv">
                  <img src="/liferayright4.png" />
                  <p>Customization and Integration</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="webportal main-home ">
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row  ">
              <div className=" col-lg-6 md-pl-14">
                <div className=" mb-40">
                  <h2
                    className="  mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "25px", color: "#cc3300" }}
                  >
                    The Leading Open Source Portal for the Enterprise
                  </h2>
                  <p
                    className="desc   md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                      color: "black",
                    }}
                  >
                    Proven real world performance with marquee clients across
                    industries. Rapid innovation with customer-contributed
                    sponsored development and new releases every 8 months.
                    <br />
                    <br />
                    A strong community with million downloads and worldwide
                    deployments Guaranteed SLAs up to 24/7/1 with regular
                    service packs.
                    <br />
                    <br />
                    Liferay DXP (Version 7.x) is designed to work within the
                    existing business processes & technologies to build a custom
                    solution that uniquely meets customer needs.
                  </p>
                </div>
              </div>
              <div className=" col-lg-6 md-pl-14">
                <img src="/liferayimg2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="webportal main-home md-pb-80" style={TextBg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row  ">
              <div className=" col-lg-6 md-pl-14">
                <img
                  src="/liferayimg4.jpeg"
                  style={{ maxWidth: "100%", height: "440px" }}
                />
              </div>
              <div
                className="col-lg-6 md-pl-14 noraml-Text main-home event-bg pt-30 pb-60 md-pt-10 md-pb-170"
                style={{ background: "transparent" }}
              >
                <div className="" data-aos="fade-up" data-aos-duration="1500">
                  <NormalText
                    itemClass="courses-item"
                    title="Can handle Critical Capabilities"
                    twoList="twoList"
                    cloudList="cloud-none"
                    listtwo=""
                    listthree="listthree-none"
                    ecommerce="ecommerce-none"
                    list1="Reduce information overload and context switching."
                    list2="Improve satisfaction with effortless purchasing"
                    list3="Monitor and increase customer adoption over time"
                    list4="Cut costs by reducing the need for customization"
                    list5="Multichannel Support"
                    list6="Enhanced Customer Experience Management"
                    list7="Personalization & Context Awareness"
                    list8="Built in Content Management"
                    list9="Analytics and Optimization"
                    list10="Cloud Capability to accelerate Digital Transformations"
                    list11="Applied Artificial Intelligence"
                    list12="Flexible Integration and Interoperability"
                    list18="Security Admin and Access Control"
                    list19="Collaboration and Knowledge Sharing"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="webportal main-home ">
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row  ">
              <div className=" col-lg-6 md-pl-14">
                <h2 style={{ fontSize: "25px", color: "#cc3300" }}>
                  An All-in-One Package
                </h2>

                <li className="black-color">
                  Liferay Portal ships with broad product capabilities to
                  provide immediate return on investment Content & Document
                  Management with Microsoft Office® integration.
                </li>
                <li className="black-color">
                  Web Publishing and Shared Workspaces.
                </li>
                <li className="black-color">Enterprise Collaboration.</li>
                <li className="black-color">Social Networking and Mashups.</li>
                <li className="black-color">
                  Enterprise Portals and Identity Management.
                </li>
              </div>

              <div className=" col-lg-6 md-pl-14 md-pt-40">
                <img src="/liferayimg5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="webportal main-home " style={Text2Bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row  ">
              <div className=" col-lg-6 md-pl-14">
                <img src="/liferayimg6.png" style={{ height: "320px" }} />
              </div>
              <div className=" col-lg-6 md-pl-14 pt-30">
                <h2 style={{ fontSize: "25px", color: "white" }}>
                  Delivering Solutions That Work
                </h2>

                <li className="white-color">
                  Enterprises are using Liferay to build robust business
                  solutions that deliver results: Self-Service Portals.
                </li>
                <li className="white-color">Knowledge Sharing Workspaces.</li>
                <li className="white-color">Dynamic Web 2.0 Websites.</li>
                <li className="white-color">
                  Revenue-generating Social Networks.
                </li>
                <li className="white-color">
                  Enterprise Application Integration.
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="liferay pt-20 pb-20">
        <div className="container">
          <div className="row liferay_align">
            <div className="span3 liferay_cons">
              <div className="">
                <img
                  alt="offer-hd-img"
                  className="ofr-hd-img"
                  src="/LiferayCardImg.jpeg"
                />
              </div>
              <div className="liferay_pln_icon">
                <i className="fa fa-pencil-square-o liferay_offer"></i>
              </div>
              <div className="ofr-card-down">
                <h4 className="liferay_esq_off">Planning Design</h4>
                <li>POC</li> <li>Architecture</li>
                <li>Wireframe/Mockup Design</li>
              </div>
            </div>
            <div className="span3 liferay_cons ">
              <div className="">
                <img
                  alt="offer-hd-img"
                  className="ofr-hd-img"
                  src="/LiferayCardImg.jpeg"
                />
              </div>
              <div className="liferay_pln_icon">
                <i className="fa fa-cogs liferay_offer1"></i>
              </div>
              <div className="ofr-card-down">
                <h4 className="liferay_esq_off1">Development</h4>
                <li>Portlet Development</li>{" "}
                <li>Enterprise Application Integration</li>{" "}
                <li>OOTB Feature Customization</li>
                <li>Content and Document management</li>
                <li>Theme Development</li>
              </div>
            </div>
            <div className="span3 liferay_cons">
              <div className="">
                <img
                  alt="offer-hd-img"
                  className="ofr-hd-img"
                  src="/LiferayCardImg.jpeg"
                />
              </div>
              <div className="liferay_pln_icon">
                <i className="fa fa-check-square-o liferay_offer2"></i>
              </div>
              <div className="ofr-card-down">
                <h4 className="liferay_esq_off2">Quality</h4>
                <li>Unit Testing</li> <li>Integration Testing</li>{" "}
                <li>Performance Testing</li>
              </div>
            </div>
            <div className="span3 liferay_cons">
              <div className="">
                <img
                  alt="offer-hd-img"
                  className="ofr-hd-img"
                  src="/LiferayCardImg.jpeg"
                />
              </div>
              <div className="liferay_pln_icon">
                <i className="fa fa-cogs  liferay_offer3"></i>
              </div>
              <div className="ofr-card-down">
                <h4 className="liferay_esq_off3">Maintenance & Support</h4>
                <li>Performance Tuning</li> <li>SEO Maintenance</li>{" "}
                <li>Application Management</li>
                <li>Technical Documentation</li>
                <li>Mobile Interface Development</li>
              </div>
            </div>
            <div className="span3 liferay_cons">
              <div className="">
                <img className="ofr-hd-img" src="/LiferayCardImg.jpeg" />
              </div>
              <div className="liferay_pln_icon">
                <i className="fa fa-pencil-square-o liferay_offer4"></i>
              </div>
              <div className="ofr-card-down">
                <h4 className="liferay_esq_off4">Devops</h4>
                <li>Admin and Developer Traning</li>{" "}
                <li>Portal Migration and Upgrada  tion</li>{" "}
                <li>Mobile Solutions</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={bg3}
      >
        <div className="container">
          <div className="row " style={{ justifyContent: "space-around" }}>
            <h2
              style={{ textAlign: "center", fontSize: "20px", color: "white" }}
            >
              Liferay Solutions
            </h2>
            <div
              className="col-lg-3  col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
              style={{
                background: "white",
                borderRadius: "4px",
                textAlign: "center",
                padding: "1rem",
              }}
            >
              <CardSingle
                itemClass="courses-item"
                image="/liferayDxp.png"
                visionicon={ImgWidth}
                textClass={blackColor}
                title="Digital Experience Platform 7.x"
                subtitle="Stay focused on your business and use our expertise to implement your digital transformation strategy."
              />
            </div>
            <div
              className="col-lg-3 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
              style={{
                background: "white",
                borderRadius: "4px",
                textAlign: "center",
                padding: "1rem",
              }}
            >
              <CardSingle
                itemClass="courses-item "
                image="/LiferayEcom.png"
                visionicon={ImgWidth}
                textClass={blackColor}
                title="E-Commerce"
                subtitle="Grow your business with an experience-led digital commerce platform that puts B2B first"
              />
            </div>
            <div
              className="col-lg-3 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
              style={{
                background: "white",
                borderRadius: "4px",
                textAlign: "center",
                padding: "1rem",
              }}
            >
              <CardSingle
                itemClass="courses-item"
                visionicon={ImgWidth}
                textClass={blackColor}
                image="/LiferayCloud.png"
                title="Analytics Cloud"
                subtitle="Launch your enterprise site with Liferay DXP Cloud and get autoscaling, development tools, environments, monitoring and much more."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="liferay pt-40 ">
        <div className="container">
          <div className="row">
            <h2
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#cc3300",
              }}
            >
              Our Services - Liferay
            </h2>
            <div
              className="col-lg-6 col-md-12 mb-30 liferay-card"
              style={cardSize}
            >
              <img src="/lifservice1.png" className="imgMain"></img>
              <img src="/liferatService1.png" className="imgIcon" />
              <div className="text-group1">
                <p className="off_spn_h4">Consulting Services</p>
                <li>Liferay DXP Consulting</li> <li>ROI Resolution</li>
                <li>Performance Fix</li>
                <li>Application Engineering Consulting</li>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 mb-30 liferay-card"
              style={cardSize}
            >
              <img src="/lifservice2.png" className="imgMain"></img>
              <img src="/liferayService2.png" className="imgIcon" />
              <div className="text-group1">
                <p className="off_spn_h4">Migration & Upgradation</p>
                <li>Migration & Upgradation Liferay DXP,</li> <li>Application Migration & Upgrade,</li>
                <li>Optimization with Migration/Upgrade</li>
                <li>Liferay License Purchasing</li>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 mb-30 liferay-card"
              style={cardSize}
            >
              <img src="/lifservice3.png" className="imgMain"></img>
              <img src="/liferayService3.png" className="imgIcon" />
              <div className="text-group1">
                <p className="off_spn_h4">Support & Maintenance</p>
                <li>Dedicated support by experts,</li> <li>Optimization and Preventive Maintenance,</li>
                <li>DevOps Services,</li>
                <li>Ceaseless Monitoring</li>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 mb-30 liferay-card"
              style={cardSize}
            >
              <img src="/lifservice4.png" className="imgMain"></img>
              <img src="/liferayService4.png" className="imgIcon" />
              <div className="text-group1">
                <p className="off_spn_h4">Customization & Integration</p>
                <li>Analysis and Strategy Designing,</li> <li>customization - Theme, portlet & module</li>
                <li>Interactive & Responsive Website,</li>
                <li>SOA Solutions</li>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BlogPartThree/>
    </React.Fragment>
  );
};

export default Liferay;
