import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const BannerStyleThree = (props:any) => {
 const {slideslice}=props;
 

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="rs-slider main-home">
        <Slider {...sliderSettings}>
          <div className="slider-content slide1">
            <div className="container">
              <div className="content-part">
                <h1 className="sl-title" style={{fontFamily:"CourierNew"}}>
              {slideslice ? slideslice.body[1].primary.text1[0].text:"OUR SERVICES LEAD YOU TO SUCCESS THAT MATTERS"} 
                </h1>
              </div>
            </div>
          </div>

          <div className="slider-content slide2">
            <div className="container">
              <div className="content-part">
                <h1 className="sl-title"  style={{fontFamily:"CourierNew"}}>
                {slideslice ? slideslice.body[1].primary.text2[0].text:"LEADING OPEN SOURCE PORTAL FOR ENTERPRISE"}
                </h1>
              </div>
            </div>
          </div>
          <div className="slider-content slide3  md-pt-38">
            <div className="container">
              <div className="content-part">
                <h1 className="sl-title"  style={{fontFamily:"CourierNew"}}>
              {slideslice ? slideslice.body[1].primary.text3[0].text:"WE WILL COVER MILES TO MAKE YOU SMILE"} 
                </h1>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </React.Fragment>
  );
};

export default BannerStyleThree;
