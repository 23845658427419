import { usePrismicDocumentByUID } from "@prismicio/react";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import mainImage from "../../assets/img/technologyinsidepic/imgbg1.jpg";




const Brochure = () => {

  const [document] = usePrismicDocumentByUID("casestudiesitems","brosureid");
  const slice7 = document && document?.data;



  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);


  const bg = {
    backgroundImage: `url(${
      slice7 ? slice7?.body[0].primary.bgimg.url :"/com.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
    height: "450px",
    backgroundRepeat: " no-repeat",
  };
  
  
  const button1 = {
    backgroundColor: "#369dd2",
    color: "#FFFFFF",
    border:"none",   
    fontWeight: "bold",
    padding: "7px 25px",
    borderRadius: "2px",
    marginLeft:"24px",
  
  };
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "140px" }}
                  >
                   {slice7
                      ? slice7?.body[0].primary.tittle[0].text
                      : "Brochure"}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-20">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <img
             src={slice7 ? slice7?.body[1].primary.leftimg.url :mainImage}
                  alt="Main Image"
                  style={{ borderRadius: "60px", width: "100%" }}
                />
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle1[0].text
                  :"Why Esquare"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[0].list[0].text
                  : "As a customer, why should you choose Esquare? We are the leading                Liferay service provider in Bangalore and we have been                delivering open source solutions to our customers for over a                decade now. We believe that the digital transformation is the                next great callenge for organizations."}             
                  <li>
                  {slice7
                  ? slice7?.body[2].items[1].list[0].text
                  : "Experts on Liferay 200 + Years of combined expertise on                  Liferay."}
                </li>
                <li>{slice7
                  ? slice7?.body[2].items[2].list[0].text
                  : "Extensive know-how on portal space"}.</li>
                <li>{slice7
                  ? slice7?.body[2].items[3].list[0].text
                  : "Team of enduring web architects."}</li>
                <li>{slice7
                  ? slice7?.body[2].items[4].list[0].text
                  : "Passion to deliver quality at a competitive costs."}</li>
                <li>{slice7
                  ? slice7?.body[2].items[5].list[0].text
                  : "Resarch & Innovation."}</li>
                <li>{slice7
                  ? slice7?.body[2].items[6].list[0].text
                  : "Collaborative Development."}</li>
              </p>

              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle2[0].text
                  :"Our Services"}
              </h2>
              <p>
                <li>{slice7
                  ? slice7?.body[2].items[7].list[0].text
                  : "Web application development."}</li>
                <li>{slice7
                  ? slice7?.body[2].items[8].list[0].text
                  : "Mobile software development."}</li>
                <li>
                  {slice7
                  ? slice7?.body[2].items[9].list[0].text
                  : "Custom software development for Windows, Mac OS and Linux."}
                </li>
                <li>{slice7
                  ? slice7?.body[2].items[10].list[0].text
                  : "Database design, development and management."}</li>
                <li>{slice7
                  ? slice7?.body[2].items[11].list[0].text
                  : "Information technology consulting."}</li>
                <li>
                  {slice7
                  ? slice7?.body[2].items[12].list[0].text
                  : "Design, redesign, support, integration and maintenance of                  custom software."}
                </li>
              </p>

              <h2 style={{ color: "	#800000" }}> {slice7
                  ? slice7?.body[2].primary.tittle3[0].text
                  : "Industry Verticals"}</h2>

              <li>{slice7
                ? slice7?.body[2].items[13].list[0].text
                : "Travel."}</li>
              <li>{slice7
                ? slice7?.body[2].items[14].list[0].text
                : "Health Care."}</li>
              <li>{slice7
                ? slice7?.body[2].items[15].list[0].text
                : "Government."}</li>
              <li>{slice7
                ? slice7?.body[2].items[15].list[0].text
                : "Education."}</li>
              <li>{slice7
                ? slice7?.body[2].items[16].list[0].text
                : "E-Commerce."}</li>
              <li>{slice7
                ? slice7?.body[2].items[17].list[0].text
                : "Manufacturing."}</li>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
          
            }}
          >
          {slice7
              ? slice7?.body[3].primary.text[0].text
              :"Click Here For Full Brochure"}
            <button style={button1}>Download</button>
          </p>
        </div>
      </div>
   
    </React.Fragment>
  );
};

export default Brochure;
