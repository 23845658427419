import React, { useState } from 'react';
import emailjs from 'emailjs-com'

const ContactForm = (props:any) => {
    const [submitStyle,setSubmitStyle]=useState(false);
    const { submitBtnClass, btnText } = props;
    function sendEmail(e:any) {
        e.preventDefault();
      
        emailjs.sendForm('service_sh4e6kj', 'template_6hgtkrj', e.target, '64aTEEbfH90uVZ4yK')
          .then((result) => {
            setSubmitStyle(true);
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });
      
      }
    
    return (
        <React.Fragment>
            {submitStyle ?<div>Your Form Has Been Submitted Successfully!!</div> :    <form onSubmit={sendEmail}>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="name" placeholder="Name" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="mail" id="email" name="email" placeholder="E-Mail" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="tel" id="phone" name="phone" placeholder="Phone Number" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="company" name="company" placeholder="Company" required />
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" placeholder="Your message Here" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit">{btnText ? btnText : 'Submit Now'}</button>
            </div>
            
        </form> }

    
        </React.Fragment>
    );

}

export default ContactForm;