const TextSingle = (props:any) => {
    return ( <div className={props.itemClass}>
        <div className="courses-grid">
            <div className="content-part" style={{height:"16rem"}}>
            
                
                <h3 className="title">
                   {props.title}
                </h3>
                <h3 className="title">
                   {props.title1}
                </h3>
                <h5 className="Crsub-title">
                   {props.subtitle}
                </h5>
               
            </div>
        </div>
    </div>  );
}
 
export default TextSingle;