import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/host1.jpg";
import img2 from "../../assets/img/serviceinsidepics/host2.jpg";
import img3 from "../../assets/img/serviceinsidepics/host3.jpg";


import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const WebHosting = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","hostid");
  const slice5 = document && document?.data;


  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/host1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/black1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.bgimg3.url:"/host2.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-pt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                   {slice5?slice5?.body[0].primary.tittle[0].text:"Web Hosting Services"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    {slice5?slice5?.body[0].primary.text1[0].text: "We provide comprehensive Web Hosting Services to our clients                    with the help of our experienced professionals.                    A web hosting service is a type of Internet hosting service                    that allows individuals and organizations to make their                    website accessible via the World Wide Web. Web hosts are                    companies that provide space on a server owned or leased for                    use by clients, as well as providing Internet connectivity,                    typically in a data center."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"Web hosts can also provide data center space and                    connectivity to the Internet for other servers located in                    their data center, called colocation, also known as Hosing."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--80"
        style={{ background: "#A09998" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Virtual Dedicated Server"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"Divides server resources into virtual servers, where resources can be allocated in a way that does not directly reflect the underlying hardware."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--50">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Cloud hosting"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Is a new type of hosting platform that allows customers powerful, scalable and reliable hosting based on clustered load-balanced servers and utility billing."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--40"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Clustered hosting"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"Clustered servers are a perfect solution for high-availability dedicated hosting, or creating a scalable web hosting solution."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-20 mbl-mb--80"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Transformation services"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"Esquare’s Salesforce Customer Experience team can help you to become a customer company."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--140">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Legacy Application Modernization"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Esquare's Salesforce expertise coupled with domain expertise across the various industries, provides end to end innovative solution to modernize your Legacy applications"}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--100"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Integrations"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"For smooth integration, our integration experts analyze and integrate enterprise applications with Sales Cloud, Service Cloud and Salesforce Platform"}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-100"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Customer Testimonial"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice5?slice5?.body[3].primary.text1[0].text:"“We need to deliver solutions that can be tailored in a cost-effective way to meet the specific needs of our contracts; and that is what esquare is helping us to do. We spent our time in selecting the right outsourcing partner for us and now reaping the rewards of our relationship with esquare, having introduced cost and delivery certainty into our business.”"}
                subtitle2={slice5?slice5?.body[3].primary.text2[0].text:"-Head of Business Systems and Development for Morrison Utility Services."}

              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default WebHosting;
