import React, { useEffect } from "react";

import img1 from "../../assets/img/technologyinsidepic/cloud.jpg";
import img2 from "../../assets/img/technologyinsidepic/security.jpg";
import img3 from "../../assets/img/technologyinsidepic/boot.jpg";
import Aos from "aos";
import CardSingle from "../Services/Card/CardSingle";
import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const SpringBoot = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","springid");
  const slice5 = document && document?.data;
  console.log(slice5);

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/spring.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/bg1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundSize: "cover",
    backgroundPosition: "cover",
    backgroundImage: `url(${slice5?slice5?.body[3].primary.listimage.url:"/online.jpg"})`,
    backgroundRepeat: " no-repeat",
    
  };
  const whiteColor = {
    color: "white",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60  " style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                     {slice5?slice5?.body[0].primary.tittle[0].text:"Spring Boot"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:"Spring Boot is a Java-based framework used to create microservices and web applications. It is built on top of the Spring framework and provides a set of tools and features for quickly creating and deploying new projects."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"Spring Boot makes it easy to get started with a new project by providing a set of pre-configured defaults and a simple, opinionated configuration. This helps developers to avoid the tedious and time-consuming task of manually configuring a new project from scratch."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#4f82c2" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Cloud"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text: "Provides support to easily develop some of the common distributed system patterns like service discovery, circuit breaker, and API gateway."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--60">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                textClass={whiteColor}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Security"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"With minimal declarative support, user get protection against common attacks like session fixation, click-jacking, and cross-site request forgery."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--60"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                textClass={whiteColor}
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Boot"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"Provides us with a set of highly opinionated but extensible template for creating various projects based on Spring in almost no time. It makes it really easy to create standalone Spring applications with embedded Tomcat or a similar container."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-4 "
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30  mbl-mb-100"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Bootstrapping saves memory space"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"Spring Boot uses Boot Initializer to compile the source language. This bootstrapping technique makes it possible for users to save space on their devices and load applications quickly."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--130">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Constant Transaction Management"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Spring provides an interface that can help scale down to a local transaction (for example, using a single database) and scale up to global transactions (for example, JTA)."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--70"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"POM dependency management"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"Spring Boot doesn’t force you to use a parent POM (project object model). Adding the spring-boot-dependencies artifact lets you manage dependencies without relying on a parent POM or XML file."}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-30 pb-80 md-pt-10 md-pb-300"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Key Features Of SpringBoot:"}
                portalList="portal-none"
                ecommerce="ecommerce-none"
                twoList="twoList"
                cloudList="cloud-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                list1={slice5?slice5?.body[3].items[0].lists[0].text:"Spring Boot can run on any web server, but it also provides an embedded web server, such as Tomcat or Jetty, which can be started with a single command."}
                list2={slice5?slice5?.body[3].items[1].lists[0].text:"Spring Boot automatically configures the application based on the dependencies that are included in the project's classpath."}
                list3={slice5?slice5?.body[3].items[2].lists[0].text:"Spring Boot provides a command-line interface (CLI) tool, which can be used to quickly create new projects and generate code. "}
                list4={slice5?slice5?.body[3].items[3].lists[0].text:"Spring Boot provides a set of development tools which makes development process faster by providing features like hot reloading, Live reload etc.    "}
                list5={slice5?slice5?.body[3].items[4].lists[0].text:"Spring Boot provides support for ORM (Object-Relational Mapping) frameworks such as JPA and Hibernate."}
                list6={slice5?slice5?.body[3].items[5].lists[0].text:"Spring Boot provides a comprehensive test framework for unit testing and integration testing."}
              
                
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default SpringBoot;
