import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SinglePostFour from "../../../components/Blog/SinglePostFour";

import blogImg1 from "../../../assets/img/blog/style2/1.png";
import blogImg2 from "../../../assets/img/blog/style2/2.png";
import blogImg3 from "../../../assets/img/blog/style2/3.png";
import blogImg4 from "../../../assets/img/blog/style2/4.png";
import blogImg5 from "../../../assets/img/blog/style2/5.png";
import blogImg6 from "../../../assets/img/blog/style2/6.png";
import blogImg7 from "../../../assets/img/blog/style2/7.png";
import blogImg8 from "../../../assets/img/blog/style2/8.jpg";
import blogImg9 from "../../../assets/img/blog/style2/9.jpg";
import blogImg10 from "../../../assets/img/blog/style2/10.jpg";
import blogImg11 from "../../../assets/img/blog/style2/11.jpg";
import { useAllPrismicDocumentsByType } from "@prismicio/react";




const CaseStudies = () => {
  const [document] = useAllPrismicDocumentsByType("home-casestudies");
  const slice12 = document && document[0]?.data;
  console.log(slice12?.body[0].items[0].tittle[0].text  );


  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);
  
const bg = {
  backgroundImage: `url(${slice12?slice12?.body[0].primary.bgimg.url:"/bg1-1.jfif"})`,
  backgroundSize: "cover",
  backgroundPosition: "right",
 
  backgroundRepeat: " no-repeat",
};
 
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60 mbl-pb-50" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "140px" }}
                  >
               {slice12?slice12?.body[0].primary.tittle[0].text:"Case Studies"}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rs-blog" className="rs-blog style2 pt-30 pb-10 md-pt-70 md-pb-20 mbl-mt--40 ">
      <div className="container">
        <div className="row">
          <div className=" col-lg-4">
          
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[0].caseimages.url:blogImg1}
        
            linkPage="/Budgetplanning"
              blogPublishedDate="June 15, 2019"
              blogTitle={slice12?slice12?.body[0].items[0].tittle[0].text :"Budget Planning and Spending Application"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[1].caseimages.url:blogImg2}
              linkPage="/Companyportal"
              blogPublishedDate="August 12, 2021"
              blogTitle={slice12?slice12?.body[0].items[1].tittle[0].text :"Company Intranet Portal"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[2].caseimages.url:blogImg3}
              linkPage="/Meetingscheduler"
              blogPublishedDate="May 5, 2020"
              blogTitle={slice12?slice12?.body[0].items[2].tittle[0].text :"Meeting Scheduler Application"}
            />{" "}
          </div>{" "}
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[3].caseimages.url:blogImg4}
         
              linkPage="/MultiLingual"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[3].tittle[0].text :"Multi lingual Wiki Portal using Liferay"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[4].caseimages.url:blogImg5}
              linkPage="/Enterprisehealth"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[4].tittle[0].text :"Enterprise Health Care Portal"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[5].caseimages.url:blogImg6}
              linkPage="/RoadAccident"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[5].tittle[0].text :"Road Accident Statistics and Street Issues Reporting Application"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[6].caseimages.url:blogImg7}
              linkPage="/TripPlanner"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[6].tittle[0].text :"Trip Planner Application using Web services"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[7].caseimages.url:blogImg8}
              linkPage="/CloudService"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[7].tittle[0].text :"Cloud Services Platform"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[8].caseimages.url:blogImg9}
              linkPage="/MobileApplication"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[8].tittle[0].text :"Mobile Application For Power Consumers"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[9].caseimages.url:blogImg10}
              linkPage="/Security"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[9].tittle[0].text :"Security Solutions for National Panchayath Portal"}
            />
          </div>
          <div className=" col-lg-4">
            <SinglePostFour
              blogImage={slice12?slice12?.body[0].items[10].caseimages.url:blogImg11}
              linkPage="/WeatherApp"
              blogPublishedDate="March 23, 2020"
              blogTitle={slice12?slice12?.body[0].items[10].tittle[0].text :"Weather Application using Webservices"}
            />
          </div>
        </div>
      </div>
      </div>
    </React.Fragment>
  );
};

export default CaseStudies;
