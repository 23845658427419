import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss';

import FooterStyleTwo from './components/Layout/Footer/FooterStyleTwo';
import Header from "../src/components/Layout/HeaderMain/Header"
import { PrismicProvider } from '@prismicio/react';
import { client } from './prismic';




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
<Header/>
<PrismicProvider client={client}>
    <App />
    </PrismicProvider>
    <FooterStyleTwo/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
