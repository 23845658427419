import { usePrismicDocumentByUID } from "@prismicio/react";
import Aos from "aos";
import React from "react";
import { useEffect } from "react";

import img1 from "../../assets/img/technologyinsidepic/main.jpg";
import img3 from "../../assets/img/technologyinsidepic/pci.jpg";
import img2 from "../../assets/img/technologyinsidepic/appl.jpg";

import CardSingle from "../Services/Card/CardSingle";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import NormalText from "../Services/NormalText/NormalText";
import TextSingle from "../Services/TextCard/TextSingle";

const Alfresco = () => {
  
  const [document] = usePrismicDocumentByUID("services_webportal","alfrescoid");
  const slice5 = document && document?.data;
  console.log(slice5?.body[3].items[0].lists[0].text);

      useEffect(() => {
        Aos.init();
        window.scrollTo(0,0);
      }, []);

      const bg = {
        backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url :"/Alfrescobg1.jfif"})`,
        backgroundSize: "cover",
        backgroundPosition: "right",
        
        backgroundRepeat: " no-repeat",
      };
      const Textbg = {
        backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url: "/Al2.jfif"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        
        backgroundRepeat: " no-repeat",
      };
      const NormalTextbg = {
        backgroundSize: "cover",
        backgroundPosition: "right center",
        backgroundImage: `url(${slice5?slice5?.body[3].primary.listimage.url:"/Alfrescobg3.jfif"})`,
        backgroundRepeat: " no-repeat",
      };
      const whiteColor = {
        color: "white",
      };

    return ( <React.Fragment>
        <div className="webportal main-home mt-130 md-mt-60" style={bg}>
          <div className="partition-bg-wrap">
            <div className="container">
              <div className="row">
                <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                  <div className="sec-title3 mb-40">
                    <h2
                      className="title white-color mb-16 Heading"
                      data-aos="fade-up"
                      data-aos-delay="50"
                      data-aos-duration="1800"
                      style={{ fontSize: "40px" }}
                    >{slice5?slice5?.body[0].primary.tittle[0].text:"Alfresco"}
                    </h2>
                    <p
                      className="desc white-color  md-pr-0 sub-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-duration="3000"
                      style={{
                        fontSize: "15px",
                        lineHeight: " 1.4",
                        textAlign: "justify",
                      }}
                    >
                      {slice5?slice5?.body[0].primary.text1[0].text:"Alfresco is the leading open source enterprise content management system built by the most experienced team in the industry - drawn from Documentum®, Vignette® and Interwoven®. Our years of experience in ECM drove us to believe that legacy, proprietary technologies are plagued with high cost, high complexity and lack of customer control. We wanted to do things differently."}
                      <br />
                      <br />
                      
                      {slice5?slice5?.body[0].primary.text2[0].text: "Alfresco has largely disrupted the proprietary ECM world with a low cost, open source alternative. But the rise of social software in the enterprise has created a new set of use cases for content management - a new, more collaborative paradigm that we (and the industry) calls Social Content Management. Alfresco's support for open source and open standards (like CMIS and JSR 168) makes Alfresco the perfect platform to manage content in this new social world. Watch our video to learn more."}
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--70"
          style={{ background: "#4f82c2" }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-6 mb-30"
                data-aos="zoom-out-right"
                data-aos-duration="1500"
              >
                <CardSingle
                  itemClass="courses-item"
                  image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                  textClass={whiteColor}
                  title={slice5?slice5?.body[1].items[0].tittle[0].text:"File Maker"}
                  subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"Esquare’s SAP HANA migration services helps unleash the potential of SAP S4/HANA with solutions focused on delivering quick business outcomes."}
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30 mbl-mt--60">
                <CardSingle
                  itemClass="courses-item"
                  image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                  textClass={whiteColor}
                  title={slice5?slice5?.body[1].items[1].tittle[0].text:"Crystal Reports"}
                  subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Today Organizations face Multiple challenges of Proper Cost visibility and Control, Reliable Support to business."}
                />
              </div>
              <div
                className="col-lg-4 col-md-6 mb-30 mbl-mt--7  0"
                data-aos="zoom-out-left"
                data-aos-duration="1500"
              >
                <CardSingle
                  itemClass="courses-item"
                  textClass={whiteColor}
                  image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                  title={slice5?slice5?.body[1].items[2].tittle[0].text:"Fast Reports"}
                  subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"Our Solution ZenDairy powered by S/4HANA is packaged solution exclusive for the dairy industries."}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-Text main-home event-bg pt-10 pb-10 md-pt-10 md-pb-120"
          style={Textbg}
        >
          <div className="container">
            <div className="row">
            <div
                className="col-lg-11 col-md-6 pt-40 mb-0"
                data-aos="fade-left"
                data-aos-duration="1500"
                
              >
                <h2 style={{fontSize:"20px",color:"white"}}>{slice5
                ? slice5?.body[2].primary.tittle[0].text:"Alfresco offers a content management platform that focuses on:"}
                </h2>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-10 mbl-mt--30"
                data-aos="fade-right"
                data-aos-duration="1500"
                
              >
                <TextSingle
                  itemClass="courses-item"
                  title={
                    slice5
                      ? slice5?.body[2].items[0].tittle[0].text
                      : "Low Cost"}
                  subtitle={
                    slice5
                      ? slice5?.body[2].items[0].subtittle[0].text
                      :"Low Cost — A low cost, open source, annual subscription with minimal upfront investment."}
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-0 mbl-mt--150">
                <TextSingle
                  itemClass="courses-item"
                  title={
                    slice5
                      ? slice5?.body[2].items[1].tittle[0].text
                      : "Simplicity"}
                  subtitle={
                    slice5
                      ? slice5?.body[2].items[1].subtittle[0].text
                      : "Simplicity — Rapid deployment to deliver immediate business value and rapid application development using pre-built components and lightweight scripting."}
                />
              </div>
              <div
                className="col-lg-4 col-md-6 mb-0 mbl-mt--100"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <TextSingle
                  itemClass="courses-item"
                  title={
                    slice5
                      ? slice5?.body[2].items[2].tittle[0].text
                      : "Choice"}
                  subtitle={
                    slice5
                      ? slice5?.body[2].items[2].subtittle[0].text
                      : "Choice — Lower Total Cost of Ownership (TCO) by reusing existing hardware, software and skills."}
                />
              </div>
              <div
                className="col-lg-11 col-md-6 mb-0 mbl-mt--100"
                data-aos="fade-left"
                data-aos-duration="1500"
              
              >
                <TextSingle
                  itemClass="courses-item"
                  title={
                    slice5
                      ? slice5?.body[2].items[3].tittle[0].text
                      : "Alfresco Enterprise Edition"}
                  subtitle={
                    slice5
                      ? slice5?.body[2].items[3].subtittle[0].text:"Alfresco Enterprise Edition is a proven, tested version of Alfresco software that is provided as part of the annual Alfresco Enterprise Subscription (learn more). Alfresco                  Enterprise Edition includes Document Management, Web Content Management, Share and the Content Platform. Records Management, as well as Enterprise add-ons                  such as clustering and a connector for content addressable storage can be added as an additional subscription on top of the base subscription. Contact Us to learn more about pricing for our Alfresco Enterprise Subscription."}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="noraml-Text main-home event-bg pt-30 pb-60 md-pt-10 md-pb-100"
          style={NormalTextbg}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-11 col-md-6 mb-30"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <NormalText
                  itemClass="courses-item"
                  title={
                    slice5
                      ? slice5?.body[3].primary.tittle[0].text
                      :"Esquare Alfresco Offerings include:"}
                  portalList="portal-none"
                  cloudList="cloud-none"
                  ecommerce=""
                  listtwo="listtwo-none"
                  listthree="listthree-none"
                  list1={slice5?slice5?.body[3].items[0].lists[0].text:"Alfresco consulting, configuration & deployment"}
                  list2={slice5?slice5?.body[3].items[1].lists[0].text:"Architectural or feasibility consultation"}
                  list3={slice5?slice5?.body[3].items[2].lists[0].text:"Customizations"}
                  list4={slice5?slice5?.body[3].items[3].lists[0].text:"Integration to other enterprise applications"}
                  list5={slice5?slice5?.body[3].items[4].lists[0].text:"On-going Support & Enhancement"}
                  list6={slice5?slice5?.body[3].items[5].lists[0].text:"Training"}
                  list14={slice5?slice5?.body[3].items[6].lists[0].text:"Alfresco Document Management and Collaboration"}
                  list15={slice5?slice5?.body[3].items[7].lists[0].text:"Alfresco Web Content Management"}
                  list16={slice5?slice5?.body[3].items[8].lists[0].text:"Alfresco Records Management"}
                  list17={slice5?slice5?.body[3].items[9].lists[0].text:"Alfresco Content Platform"}
                />
              </div>
            </div>
          </div>
        </div>
        <LifeSpan/>
      </React.Fragment> );
}
 
export default Alfresco;