import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/web1-1.jpg";
import img2 from "../../assets/img/serviceinsidepics/web1-2.jpg";
import img3 from "../../assets/img/serviceinsidepics/web1-3.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const WebPortal = () => {
  const [document] = usePrismicDocumentByUID("services_webportal", "serviceid");

  const slice5 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${
      slice5 ? slice5?.body[0].primary.bgimg1.url : "/web1.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "right",

    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${
      slice5 ? slice5?.body[2].primary.bg_img.url : "/black1.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${
      slice5 ? slice5?.body[3].primary.bgimg3.url : "/web3.jpg"
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home   mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 pt-60 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice5
                      ? slice5?.body[0].primary.tittle[0].text
                      : "Web Portal Development"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                    {slice5
                      ? slice5?.body[0].primary.text1[0].text
                      : "Esquare team works together with the client as a partner to help provide solutions which fit their requirements in any environment. We start right from beginning by understanding the clients requirements, Planning and designing, Developing and implementing the solutions with any type and number of       integrations by creating a one stop shop for our clients.Our highly skilled developers have worked on Open Source for several years, and helped in implementing many complex projects and development challenges."}
                    <br />
                    <br />
                    {slice5
                      ? slice5?.body[0].primary.text2[0].text
                      : "Web development is the work involved in developing a website for the Internet or an intranet. Web development can range from developing a simple single static page of plain text to complex web-based internet applications electronic  businesses, and social network services."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--80"
        style={{ background: "#6B5B95" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5 ? slice5?.body[1].items[0].cardimages.url : img1}
                title={
                  slice5
                    ? slice5?.body[1].items[0].tittle[0].text
                    : "BPM life-cycle"
                }
                subtitle={
                  slice5
                    ? slice5?.body[1].items[0].subtittle[0].text
                    : "Business process management activities can be grouped into six categories: vision, design, modeling, execution, monitoring, and optimization."
                }
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--40">
              <CardSingle
                itemClass="courses-item"
                image={slice5 ? slice5?.body[1].items[1].cardimages.url : img2}
                title={
                  slice5
                    ? slice5?.body[1].items[1].tittle[0].text
                    : "BPM Technology"
                }
                subtitle={
                  slice5
                    ? slice5?.body[1].items[1].subtittle[0].text
                    : "Business process management (BPM) tools can be used to implement business processes through the orchestration of activities between people and systems."
                }
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--40"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5 ? slice5?.body[1].items[2].cardimages.url : img3}
                title={
                  slice5 ? slice5?.body[1].items[2].tittle[0].text : "Execution"
                }
                subtitle={
                  slice5
                    ? slice5?.body[1].items[2].subtittle[0].text
                    : "A combination of software and human intervention is used to execute the processes as per the designed flow."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-10 "
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={
                  slice5
                    ? slice5?.body[2].items[0].tittle[0].text
                    : "Partners Solutions"
                }
                subtitle={
                  slice5
                    ? slice5?.body[2].items[0].subtittle[0].text
                    : "Esquare team works together with the client as a partner provide solutions which fit their requirements in any environment.Web development broadly refers to the tasks associated with developing websites for hosting via intranet or internet. The web development process includes web design, web content development, client-side/server-side scripting and network security configuration, among other tasks."
                }
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <TextSingle
                itemClass="courses-item"
                title={
                  slice5
                    ? slice5?.body[2].items[1].tittle[0].text
                    : "Requirements"
                }
                subtitle={
                  slice5
                    ? slice5?.body[2].items[1].subtittle[0].text
                    : "We start right from beginning by understanding the clients requirements,The role is responsible for designing, coding and modifying websites, from layout to function and according to a client's specifications. Strive to create visually appealing sites that feature user-friendly design and clear navigation."
                }
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--40"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={
                  slice5
                    ? slice5?.body[2].items[2].tittle[0].text
                    : "Planning And Designing"
                }
                subtitle={
                  slice5
                    ? slice5?.body[2].items[2].subtittle[0].text
                    : "Planning and designing, Developing and implementing the solutions with any type and number of integrations by creating a one stop shop for our clients.After programming schematics, plans, specifications, and construction documents have been determined, Planning & Design hands over the project to Construction Services, which oversees the construction and execution of the plans."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-290"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={
                  slice5
                    ? slice5?.body[3].primary.tittle[0].text
                    : "Web development"
                }
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={
                  slice5
                    ? slice5?.body[3].primary.text1[0].text
                    : "We don't satisfy your need but exceed your expectations by providing resources who can manage your projects and build your resource capabilities by mentoring and providing solutions which are required to meet your requirements. In turn increasing your profitability and helping you succeed."
                }
                subtitle2={
                  slice5
                    ? slice5?.body[3].primary.text2[0].text
                    : "Design relates to creating something that will resonate with the intended user on several levels, including emotional, social, cultural, physical, and cognitive. Design planning includes: Problem framing and hypothesis development. Research (contextually based using both quantitative and qualitative methods)."
                }
                subtitle3={
                  slice5
                    ? slice5?.body[3].primary.text3[0].text
                    : " Network planning and design is an iterative process, encompassing topological design, network-synthesis, and network-realization."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default WebPortal;
