import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/web1-1.jpg";
import img2 from "../../assets/img/serviceinsidepics/web1-2.jpg";
import img3 from "../../assets/img/serviceinsidepics/web1-3.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Ecommerce = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","ecommerceid");
  const slice5 = document && document?.data;
  console.log(slice5);

  
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/E1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[1].primary.bg_img.url:"/black1.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.listimage.url:"/web3.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >  {slice5?slice5?.body[0].primary.tittle[0].text:"E-commerce Solutions"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                   {slice5?slice5?.body[0].primary.text1[0].text: "E2 Software is a company that specializes in providing                    e-commerce solutions for businesses of all sizes. The                    company was founded with the goal of helping businesses                    establish a strong online presence and reach more customers                    through the use of e-commerce technology.                                    E2 Software offers a range of e-commerce services, including                    the development and design of e-commerce websites,                    integration with third-party systems and platforms, and                    ongoing support and maintenance."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:"The company's team of experienced developers and designers                    works closely with clients to understand their specific                    needs and goals, and they use their expertise to create                    custom solutions that meet these needs."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-2 mbl-mb--40"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"E - commerce"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"E-commerce can be a fully integrated solution or a technical front-end' to a business that otherwise isn't wired. You can advertise for your offline store online and drive traffic to it, or you can offer your customers to purchase from your store online. Today, millions of businesses are linked online and it has proved to be very beneficial financially."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--40">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Statistics"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Take these statistics: 57% of consumers are likely to take action based on the information from a brand representative, and 62% of consumers have indicated that information from a brand representative would be more valuable than advertisements or promotional materials. Shoppers can visit the Web site at their convenience to find the information they need to purchase a product."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--20"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Complexities"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"We at Esquare understand the complexities of an E-commerce portal and provide a simplified solution in building a robust E-commerce portal which is scalable. We provide solutions which include technologies like Magento."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-20"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-100"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[2].primary.tittle[0].text:"Features of E-commerce"}
                listClass="Features of E-commerce"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce=""
                listtwo="listtwo-none"
                listthree="listthree-none"
                list1={slice5?slice5?.body[2].items[0].lists[0].text:" Online Shopping."}
                list2={slice5?slice5?.body[2].items[1].lists[0].text:"      Online Banking."}
                list3={slice5?slice5?.body[2].items[2].lists[0].text:"  Content Delivery."}
                list4={slice5?slice5?.body[2].items[3].lists[0].text:"    Order Tracking."}
                list5={slice5?slice5?.body[2].items[4].lists[0].text:"    Shopping Cart."}
                list6={slice5?slice5?.body[2].items[5].lists[0].text:"   Payment Systems."}
                list14={slice5?slice5?.body[2].items[6].lists[0].text:"Social Networking & Review System."}
                list15={slice5?slice5?.body[2].items[7].lists[0].text:"Electronic Tickets."}
                list16={slice5?slice5?.body[2].items[8].lists[0].text:"  Email Marketing."}
                list17={slice5?slice5?.body[2].items[9].lists[0].text:"  Instant Messaging. "}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Ecommerce;
