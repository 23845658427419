import React from "react";

import SectionTitle from "../../components/Common/SectionTitle";

import ContactForm from "../../components/Contact/ContactForm";

const ContactMain = (props:any) => {
  const {contact_Details}=props;

  return (
    <React.Fragment>
      <div className="rs-contact style4 pt-1 md-pt-10 pb-30 md-pb-80">
        <div className="container">
          <div className="form-part mt-60">
            <div className="row md-pl-pr">
              {/* <div className="col-lg-6 pr-40 md-mb-30">
                <div className="map-canvas">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.25920364363!2d-1.8484171000000003!3d52.4744424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bba7538ecf39%3A0x3ffa6c2ebfd20c62!2s377%20Green%20Ln%2C%20Birmingham%20B9%205PP%2C%20UK!5e0!3m2!1sen!2sin!4v1672216021488!5m2!1sen!2sin"
                    width="800"
                    height="450"
                  ></iframe>
                </div>
              </div> */}
              <div className="col-lg-12 pl-3">
                <div className="contact-widget event-bg">
                  <SectionTitle
                    sectionClass="sec-title mb-50 xs-mb-30"
                    titleClass="title orange-color mb-14"
                    title={contact_Details?contact_Details.body[2].primary.tittle[0].text:"Get In Touch"}
                    descClass="desc big"
                    description={
                      <>
                        {contact_Details?contact_Details.body[2].primary.text1[0].text:"Any time a customer comes into contact with any aspect                        of a business, however remote, is an opportunity to form                        an impression."}
                        <br />
                        {contact_Details?contact_Details.body[2].primary.text2[0].text: "-JAN CARLZON"}
                      </>
                    }
                  />

                  {/* Contact Form */}
                  <ContactForm submitBtnClass="btn-send" btnText="Submit" />
                  {/* Contact Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;
