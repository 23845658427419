import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../../pages/Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/mob1.jpg";
import img2 from "../../assets/img/serviceinsidepics/mob2.jpg";
import img3 from "../../assets/img/serviceinsidepics/mob3.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const MobileApp = () => {


  const [document] = usePrismicDocumentByUID("services_webportal","mobileid");
  const slice5 = document && document?.data;


  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/mob1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/mob2.gif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.listimage.url:"/mob3.jpg"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-pt-40">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                      {slice5?slice5?.body[0].primary.tittle[0].text:"Mobile Application Development"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                     {slice5?slice5?.body[0].primary.text1[0].text:"A running images list of technologies like : IOS, Andriod,                    Blackberry, Symbian, windows etc.                   Is the set of processes and procedures involved in writing                    software for small, wireless computing devices such as                    smartphones or tablets. Mobile application development is                    similar to Web application development and has its roots in                    more traditional software development."}
                    <br />
                    <br />
                    {slice5?slice5?.body[0].primary.text2[0].text:" Mobile app development is the act or process by which a                    mobile app is developed for mobile devices, such as personal                    digital assistants, enterprise digital assistants or mobile                    phones."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--20"
        style={{ background: "#6B5B95" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt-20"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Retail"}
                subtitle=
                {slice5?slice5?.body[1].items[0].subtittle[0].text: "We help global retailers deliver superior customer experience with capabilities in commerce, in-store tools, analytics and much more. Read more to rewrite retail with technology."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Manufacturing"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"We have helped large manufacturing organisations gain agility and flexibility with clear and achievable goals consistently."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--40"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"Ecommerce"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"We work with some of the world’s largest ecommerce companies to help them deploy, leverage and create a seamless ecommerce experience for today’s growing breed of the digital customer."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-2 mbl-mb--80"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Scenario"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"You and your team are constantly on the move or just want to access data and systems remotely and you cannot afford to limit your business down to the traditional processes, as you understand that we cannot stay in business today with yesterday's processes & methodologies."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30 mbl-mt--70">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Solution"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"Our Mobile Application Development Services company is the one stop solution where we equip you with the power to access your information, Systems, Applications, Data, reports and much more as you name it anytime, anywhere for anyone you authorize using an handheld device."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30 mbl-mt--50"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Enterprise Data Management"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"Leave your data problems to us: Data is a true asset however data problems are way too many for any organization to handle."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-10"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Solution"}
                listClass=""
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                list1={slice5?slice5?.body[3].items[0].lists[0].text:"iPad application development."}
                list2={slice5?slice5?.body[3].items[1].lists[0].text:"iphone application development."}
                list4={slice5?slice5?.body[3].items[2].lists[0].text:"Mobile Web application development."}
                list5={slice5?slice5?.body[3].items[3].lists[0].text:"Android application development."}
                list6={slice5?slice5?.body[3].items[4].lists[0].text:"Windows Phone application development."}
                list3={slice5?slice5?.body[3].items[5].lists[0].text:"BlackBerry application development."}
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default MobileApp;
