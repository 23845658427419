import React, { useContext } from "react";
import Slider from "react-slick";
import SinglePostFour from "./SinglePostFour";

import { sliceBanner } from "../Context/Context";
const items = [
  {
    image: "/qwerty/1.png",

    title: "Budget Planning and Spending Application",
    link: "/Budgetplanning",
  },
  {
    image: "/qwerty/2.png",
    title: "Company Intranet Portal",
    link: "/Companyportal",
  },
  {
    image: "/qwerty/3.png",
    title: "Meeting Scheduler Application",
    link: "/Meetingscheduler",
  },
  {
    image: "/qwerty/4.png",
    title: "Multi lingual Wiki Portal using Liferay",
    link: "/MultiLingual",
  },
  {
    image: "/qwerty/5.png",
    title: "Enterprise Health Care Portal",
    link: "/Enterprisehealth",
  },

  {
    image: "/qwerty/6.png",
    title: "Road Accident Statistics and Street Issues Reporting Application",
    link: "/RoadAccident",
  },
  {
    image: "/qwerty/7.png",
    title: "Trip Planner Application using Web services",
    link: "/TripPlanner",
  },
  {
    image: "/qwerty/8.jpg",
    title: "Cloud Services Platform",
    link: "/CloudService",
  },
  {
    image: "/qwerty/9.jpg",
    title: "Mobile Application For Power Consumers",
    link: "/MobileApplication",
  },
  {
    image: "/qwerty/10.jpg",
    title: "Security Solutions for National Panchayath Portal",
    link: "/Security",
  },
  {
    image: "/qwerty/11.jpg",
    title: "Weather Application using Webservices",
    link: "/WeatherApp",
  },
];
const BlogPartThree = (props: any) => {
  const sliceBannesr = useContext(sliceBanner);
  console.log(sliceBannesr);

  const blogSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const NormalData = items.map((item, i) => {
    return (
      <SinglePostFour
        blogImage={item.image}
        blogTitle={item.title}
        key={i}
        linkPage={item.link}
      />
    );
  });
  const PrismicCardData = sliceBannesr?.body[7]?.items?.map(
    (item: any, i: any) => {
      return (
        <SinglePostFour
          blogImage={item.caseimage.url}
          blogTitle={item.casetext[0].text}
          key={i}
        />
      );
    }
  );
  return (
    <React.Fragment>
      <div className="container">
        <Slider {...blogSettings}>
          {sliceBannesr ? NormalData : PrismicCardData}
        </Slider>
      </div>
    </React.Fragment>
  );
};

export default BlogPartThree;
