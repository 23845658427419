import { usePrismicDocumentByUID } from "@prismicio/react";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import mainImage from "../../../assets/img/blog/style2/9.jpg";


const MobileApplication = () => {
  const [document] = usePrismicDocumentByUID("casestudiesitems","mobileappid");
  const slice7 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);

  
const bg = {
  backgroundImage: `url(${
    slice7 ? slice7?.body[0].primary.bgimg.url :"/mobile.jfif"})`,
  backgroundSize: "cover",
  backgroundPosition: "right",
  height: "510px",
  backgroundRepeat: " no-repeat",
};

const button1 = {
  backgroundColor: "#369dd2",
  color: "#FFFFFF",
  border: "none",
  fontWeight: "bold",
  padding: "7px 25px",
  borderRadius: "2px",
  marginLeft: "24px",
};
  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "140px" }}
                  >
                   {slice7
                      ? slice7?.body[0].primary.tittle[0].text
                      : "Case Study"}
                    <p style={{ fontSize: "20px" }}>
                    {slice7
                        ? slice7?.body[0].primary.text[0].text
                        : "Mobile Application For Power Consumers"}
                    </p>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-20">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <img
                  src={slice7 ? slice7?.body[1].primary.leftimg.url :mainImage}
                  alt="Main Image"
                  style={{ borderRadius: "30px", width: "100%" }}
                />
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
             <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle1[0].text
                  :"Business Need"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[0].list[0].text
                  : "This Enterprise customer wanted to build a consumer mobile                application. This application will be the principle tool for the                consumer to know their energy consumption, pay & view their                bills, get energy saving tips, Grievances & Feedback, Outage                Intimation & Status, participate in demand response program and                Remote connection/disconnection of load."}
              </p>

              <h2 style={{ color: "	#800000" }}>{slice7
                  ? slice7?.body[2].primary.tittle2[0].text
                  :"Challenges"}</h2>
              <p>
              {slice7
                  ? slice7?.body[2].items[1].list[0].text
                  : "The existing portal was developed by a third party company using                MS .Net and deployed in IIS server. As per their production                operations team, they had multiple DB failures and the system                was not meant for high scalability to support a huge set of                consumers. There were no REST Webservices available for the                mobile apps to obtain the data from the middleware. The current                production setup was hosted in a small data center that did not                have the facility for high availability/scalability/performance.                Promotion and creating awareness among consumers about the                mobile application and its usage"}
              </p>

              <h2 style={{ color: "	#800000" }}> {slice7
                  ? slice7?.body[2].primary.tittle3[0].text
                  : "Key Features"}</h2>

              <li>
                {" "}
                {slice7
                  ? slice7?.body[2].items[2].list[0].text
                  : "Consumer delight by providing this handy & cognitive tool."}
              </li>
              <li> {slice7
                  ? slice7?.body[2].items[3].list[0].text
                  : "Enabling Participation of consumer in demand Response."}</li>
              <li>
                {" "}
                {slice7
                  ? slice7?.body[2].items[4].list[0].text
                  : "To be competitive by setting new trends & benchmarking                practices."}
              </li>
              <li>
                {" "}
                {slice7
                  ? slice7?.body[2].items[5].list[0].text
                  : "Driving force for other Smart grid initiatives & Visualization                by consumer."}
              </li>
              <li>
                {" "}
                {slice7
                  ? slice7?.body[2].items[6].list[0].text
                  : "To stabilize the peak load demand by facilitating speedy and                swift interaction with consumers."}
              </li>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
               {slice7
              ? slice7?.body[3].primary.text[0].text
              :"Click Here For Full Brochure"}
            <button style={button1}>Download</button>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileApplication;
