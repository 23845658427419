import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";

import SectionTitle from "../../components/Common/SectionTitle";

import animateBall from "../../assets/img/about/image-center-circle.png";
import mainImage from "../../assets/img/about/about3_.jpg";

const CTA = (props: any) => {
  const { ctaslice } = props;

  useEffect(() => {
    AOS.init();
  });

  return (
    <div
      id="rs-about"
      className="rs-about style1 pt-60 pb-20 md-pt-30 md-pb-30"
    >
      <div className="container">
        <div className="row align-items-center ">
          <div className="col-lg-7 pl-60 order-last md-pl-14 md-mb-60">
            <div className="img-part">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <img src={ctaslice?ctaslice.body[3].primary.rightimg.url:mainImage} alt="Main Image" />
                <img
                  className="shape top-center rotateme"
                  src={animateBall}
                  alt="Rotating Ball"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            {/* Section Title Start */}
            <SectionTitle
              titleClass="title"
              title={
                <>
                  {ctaslice?ctaslice.body[3].primary.title1[0].text:"Esquare Simplifying Open Source!"} <br /> {ctaslice?ctaslice.body[3].primary.title2[0].text: "looks and how it functions."}
                </>
              }
              descClass="desc pr-100"
              description={ctaslice?ctaslice.body[3].primary.dec1[0].text:"Esquare is into Liferay and other open-source portal Development, Consulting and Corporate Training. With great industry knowledge We delivers high quality products creating value to its customers."}
              animateName="fade-up"
              animateDuration="1200"
            />
            {/* Section Title End */}
            <div
              className="btn-part  pt-20"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="280"
            >
              <Link className="readon2" to="/company">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
