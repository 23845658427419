import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import iconimg3 from "../../assets/img/technologyinsidepic/hand.png";
import iconimg2 from "../../assets/img/technologyinsidepic/dat.png";
import iconimg1 from "../../assets/img/technologyinsidepic/cld.png";

import mainImage from "../../assets/img/technologyinsidepic/bg2.jpg";

import TextSingle from "../../pages/Services/TextCard/TextSingle";
import NormalText from "../../pages/Services/NormalText/NormalText";
import LifeSpan from "../../pages/Services/lifeSpan-Contact/Lifespan";
import Bigblog from "./Bigblog";
import { useAllPrismicDocumentsByType, usePrismicDocumentByUID } from "@prismicio/react";

const Bigdata = () => {
  const [document] = usePrismicDocumentByUID("technologies","bigdataid");
  const slice33 = document && document?.data;
 

 

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice33?slice33?.body[0].primary.bgimg1.url:"/big.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",

    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice33?slice33?.body[2].primary.bg_img.url:"/bg1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: " no-repeat",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14 mbl-mt-40">
                <div className="sec-title3 mb-40 pt-10">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px" }}
                  >
                    {slice33?slice33?.body[0].primary.tittle[0].text:"Big Data"}
                  </h2>
                  <p
                    className="desc white-color md-mb-100 md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                   {slice33?slice33?.body[0].primary.text1[0].text:"Big data is a term that describes the large volume of data –                    both structured and unstructured – that inundates a business                    on a day-to-day basis. But it’s not the amount of data                    that’s important. It’s what organizations do with the data                    that matters. Big data can be analyzed for insights that                    lead to better decisions and strategic business moves."}
                    <br />
                    <br />
                    {slice33?slice33?.body[0].primary.text2[0].text:" There's nothing new about the notion of big data, which has                    been around since at least 2001. In a nutshell, Big Data is                    your data. It's the information owned by your company,                    obtained and processed through new techniques to produce                    value in the best way possible. Even the value in analyzing                    unstructured data such as e-mail and documents has been well                    understood. What is new is the coming together of advances                    in computer technology and software, new sources of data                    (e.g., social media), and business opportunity. Governments                    and companies are able to integrate personal data from                    numerous sources and learn much of what you do, where you                    go, who your friends are, and what your preferences are.                    Although this leads to better service (and profits for                    companies), it also raises privacy concerns."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="rs-blog"
        className="rs-blog style2 pt-40 pb-10 md-pt-70 md-pb-2 mbl-mt--30 "
      >
        <Bigblog Cardslice2={slice33}/>
      </div>

      <div
        id="rs-features"
        className="rs-features style4 pt-40  md-pt-20 md-pb-30"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4 mbl-mt-40">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                  <img src={slice33?slice33?.body[2].items[0].icons.url:iconimg1} alt="Image" />
                </div>
                <div className="content-part">
                  <h4 className="title">
                    <span className="watermark"style={{ color: "#ffc266" }}>{slice33?slice33?.body[2].items[0].tittle[0].text:"Volume"}</span>
                  </h4>
                  <p className="dese white-color">
                    {slice33?slice33?.body[2].items[0].subtittle[0].text:"Volume Organizations collect data from a variety of sources,                    including business transactions, social media and                    information from sensor or machine-to-machine data. In the                    past, storing it would’ve been a problem – but new                    technologies (such as Hadoop) have eased the burden."}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mbl-mt-40">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                  <img src={slice33?slice33?.body[2].items[1].icons.url:iconimg2} alt="" />
                </div>
                <div className="content-part">
                  <h4 className="title">
                    <span className="watermark"style={{ color: "#ffc266" }}>{slice33?slice33?.body[2].items[1].tittle[0].text:"Variability"}</span>
                  </h4>
                  <p className="dese white-color">
                  {slice33?slice33?.body[2].items[1].subtittle[0].text:"Variability Complexity In addition to the increasing                    velocities and varieties of data, data flows can be highly                    inconsistent with periodic peaks. Is something trending in                    social media? Daily, seasonal and event-triggered peak data                    loads can be challenging to manage."}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mbl-mt-40">
              <div className="features-wrap">
                <div className="icon-part" style={{ textAlign: "center" }}>
                  <img src={slice33?slice33?.body[2].items[2].icons.url:iconimg3} alt="" />
                </div>
                <div className="content-part">
                  <h4 className="title">
                    <span className="watermark"style={{ color: "#ffc266" }}>{slice33?slice33?.body[2].items[2].tittle[0].text:"Complexity"}</span>
                  </h4>
                  <p className="dese white-color">
                  {slice33?slice33?.body[2].items[2].subtittle[0].text:" Today's data comes from multiple sources, which makes it                    difficult to link, match, cleanse and transform data across                    systems. However, it’s necessary to connect and correlate                    relationships"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 mb-50"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#800000" }}>{slice33?slice33?.body[3].primary.tittle[0].text:"Why Is Big Data Important"}</h2>

              <li>{slice33?slice33?.body[3].items[0].list_or_content[0].text:"Cost reductions"}</li>
              <li>{slice33?slice33?.body[3].items[0].list_or_content[1].text:"Time reductions"}</li>
              <li>{slice33?slice33?.body[3].items[0].list_or_content[2].text:"New product development and optimized offerings"}</li>
              <li>{slice33?slice33?.body[3].items[0].list_or_content[3].text:"Smart decision making."}</li>
              <li>
                {slice33?slice33?.body[3].items[0].list_or_content[4].text:"Determining root causes of failures, issues and defects in                near-real time."}
              </li>
              <li>
              {slice33?slice33?.body[3].items[0].list_or_content[5].text:"Generating coupons at the point of sale based on the customer’s                buying habits."}
              </li>
              <li>{slice33?slice33?.body[3].items[0].list_or_content[6].text:"Recalculating entire risk portfolios in minutes."}</li>
              <li>
              {slice33?slice33?.body[3].items[0].list_or_content[7].text:"Detecting fraudulent behavior before it affects your                organization."}
              </li>
              <li>{slice33?slice33?.body[3].items[0].list_or_content[8].text:"Organizations collect data from a variety of sources"}</li>
              <li>
              {slice33?slice33?.body[3].items[0].list_or_content[9].text:"Data streams in at an unprecedented speed and must be dealt with                in a timely manner."}
              </li>
              <li>
              {slice33?slice33?.body[3].items[0].list_or_content[10].text:"Including business transactions, social media and information from sensor or machine-to-machine data."} </li>
            </div>
            <div className="col-lg-6 col-md-6 mb-50">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <img src={slice33?slice33?.body[3].primary.bg_and_normal_img.url:mainImage} alt="Main Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Bigdata;
