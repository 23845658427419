import Aos from "aos";
import React, { useEffect } from "react";
import img1 from "../../assets/img/serviceinsidepics/web2-2.png";
import img2 from "../../assets/img/serviceinsidepics/web2-3.png";
import img3 from "../../assets/img/serviceinsidepics/train3.jpg";
import CardSingle from "../Services/Card/CardSingle";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import NormalText from "../Services/NormalText/NormalText";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Joomla = () => {
  const [document] = usePrismicDocumentByUID("services_webportal","joomlaid");
  const slice5 = document && document?.data;
  console.log(slice5?.body[2].items[0].lists[0].text);

  useEffect(() => {
    Aos.init();
    window.scrollTo(0,0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url :"/joomlabg1.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",

    backgroundRepeat: " no-repeat",
  };

  const NormalTextbg = {
    backgroundSize: "cover",
    backgroundPosition: "cover",
    backgroundImage: `url(${slice5?slice5?.body[2].primary.listimage.url :"joomlabg2.jpg"})`,
    backgroundRepeat: " no-repeat",
  };
  const whiteColor = {
    color: "white",
  };

    return ( <React.Fragment>
        <div className="webportal main-home  mt-130 md-mt-60 " style={bg}>
          <div className="partition-bg-wrap">
            <div className="container">
              <div className="row">
                <div className=" col-lg-6 md-pl-14">
                  <div className="sec-title3 mb-40 pt-40">
                    <h2
                      className="title white-color mb-16 Heading"
                      data-aos="fade-up"
                      data-aos-delay="50"
                      data-aos-duration="1800"
                      style={{ fontSize: "40px" }}
                    >
                     {slice5?slice5?.body[0].primary.tittle[0].text:"Joomla"}
                    </h2>
                    <p
                      className="desc white-color  md-pr-0 sub-text"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-duration="3000"
                      style={{
                        fontSize: "15px",
                        lineHeight: " 1.4",
                        textAlign: "justify",
                      }}
                    >
                     {slice5?slice5?.body[0].primary.text1[0].text:"Joomla is an award-winning content management system (CMS), which enables you to build Web sites and powerful online applications. Many aspects, including its ease-of-use and extensibility, have made Joomla the most popular Web site software available. Best of all, Joomla is an open source solution that is freely available to everyone."}
                      <br />
                      <br />
                      {slice5?slice5?.body[0].primary.text2[0].text:"Joomla is the most popular open source CMS currently available as evidenced by a vibrant and growing community of friendly users and talented developers. Joomla's roots go back to 2000 and, with over 200,000 community users and contributors, the future looks bright for the award-winning Joomla Project.Joomla is an open source Content Management System (CMS), which is used to build websites and online applications. It is free and extendable which is separated into front-end and back-end templates (administrator). Joomla is developed using PHP, Object Oriented Programming, software design patterns and MySQL (used for storing the data)"}
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0 mbl-mb--50"
          style={{ background: "#4f82c2" }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-6 mb-30"
                data-aos="zoom-out-right"
                data-aos-duration="1500"
              >
                <CardSingle
                  itemClass="courses-item"
                  image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                  textClass={whiteColor}
                  title={slice5?slice5?.body[1].items[0].tittle[0].text:"QT"}
                  subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"Esquare’s Sales force Customer Experience team can help you to become a customer company.Clean and Fresh Styles Joomla Template based on the Ganry Framework and have unlimited background colors."}
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30 mbl-mt--40">
                <CardSingle
                  itemClass="courses-item"
                  image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                  textClass={whiteColor}
                  title={slice5?slice5?.body[1].items[1].tittle[0].text:"Embedded C"}
                  subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"Esquare's Salesforce expertise coupled with domain expertise across the various industries.Sourcerer enables you to place PHP and any kind of HTML style code (including CSS and JavaScript) right in to your content! Not only in your articles, but also in sections, categories, modules, components, META tags, etc"}
                />
              </div>
              <div
                className="col-lg-4 col-md-6 mb-30"
                data-aos="zoom-out-left"
                data-aos-duration="1500"
              >
                <CardSingle
                  itemClass="courses-item"
                  textClass={whiteColor}
                  image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                  title={slice5?slice5?.body[1].items[2].tittle[0].text:"Visual C++"}
                  subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"For smooth integration, our integration experts analyze and integrate enterprise applications with Sales Cloud, Service Cloud and Salesforce Platform with optimistic approach."}
                />
              </div>
            </div>
          </div>
        </div>
      
        <div
          className="noraml-Text main-home event-bg pt-30 pb-10 md-pt-10  md-pb-20 "
          style={NormalTextbg}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 mb-0 mbl-mt-30"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <NormalText
                  itemClass="courses-item"
                  title={slice5?slice5?.body[2].primary.tittle[0].text:"Our Client Say"}
                  listClass="display-none"
                  portalList="portal-none"
                  twoList="twoList"
                  ecommerce=""
                  listtwo="listtwo-none"
                  listthree="listthree-none"
                  cloudList="cloud-none"
                  list14={slice5?slice5?.body[2].items[0].lists[0].text:"Corporate Web sites or portals"}
                  list15={slice5?slice5?.body[2].items[1].lists[0].text:"Corporate intranets and extranets."}
                  list16={slice5?slice5?.body[2].items[2].lists[0].text:"Non-profit and organizational Web sites"}
                  list17={slice5?slice5?.body[2].items[3].lists[0].text:"Community-based portals"}
                />
              </div>
              <div
                className="col-lg-4 col-md-4 pt-22 mbl-mt--70 mbl-mt--30"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <NormalText
                  itemClass="courses-item"
                  listClass="display-none"
                  portalList="portal-none"
                  cloudList=""
                  ecommerce="ecommerce-none"
                  listtwo=""
                  listthree="listthree-none"
                  list13={slice5?slice5?.body[2].items[4].lists[0].text:"Online magazines, newspapers, and publications"}
                  list18={slice5?slice5?.body[2].items[5].lists[0].text:"E-commerce and online reservations"}
                  list19={slice5?slice5?.body[2].items[6].lists[0].text:"School and church Web sites"}
                />
              </div>
              <div
                className="col-lg-4 col-md-4 pt-22 mb-0 mbl-mt--90"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <NormalText
                  itemClass="courses-item"
                  listClass="display-none"
                  portalList="portal-none"
                  cloudList=""
                  ecommerce="ecommerce-none"
                  listtwo=""
                  listthree="listthree-none"
                  list13={slice5?slice5?.body[2].items[7].lists[0].text:"Government applications"}
                  list18={slice5?slice5?.body[2].items[8].lists[0].text:"Small business Web sites"}
                  list19={slice5?slice5?.body[2].items[9].lists[0].text:"Personal or family homepages"}
                
                />
              </div>
            </div>
          </div>
        </div>
        <LifeSpan/>
      </React.Fragment> );
}
 
export default Joomla;