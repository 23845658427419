import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardSingle from "../Services/Card/CardSingle";
import img1 from "../../assets/img/serviceinsidepics/comcover.jpg";
import img2 from "../../assets/img/serviceinsidepics/guide.jpg";
import img3 from "../../assets/img/serviceinsidepics/international.jpg";
import TextSingle from "../Services/TextCard/TextSingle";
import NormalText from "../Services/NormalText/NormalText";
import LifeSpan from "../Services/lifeSpan-Contact/Lifespan";
import { usePrismicDocumentByUID } from "@prismicio/react";

const Hrms = () => {

  
  const [document] = usePrismicDocumentByUID("services_webportal","hrmsid");
  const slice5 = document && document?.data;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);

  const bg = {
    backgroundImage: `url(${slice5?slice5?.body[0].primary.bgimg1.url:"/Hrms.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
    height: "590px",
    backgroundRepeat: " no-repeat",
  };
  const Textbg = {
    backgroundImage: `url(${slice5?slice5?.body[2].primary.bg_img.url:"/hrms2.png"})`,
    backgroundSize: "cover",
    height: "250px",
    backgroundPosition: "center",
  
    backgroundRepeat: " no-repeat",
  };
  const NormalTextbg = {
    backgroundImage: `url(${slice5?slice5?.body[3].primary.bgimg3.url:"/hrms3.jfif"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "250px",
    backgroundRepeat: " no-repeat",
  };

  return (
    <React.Fragment>
      <div className="webportal main-home  mt-130 md-mt-60" style={bg}>
        <div className="partition-bg-wrap">
          <div className="container">
            <div className="row">
              <div className=" col-lg-6 md-pl-14">
                <div className="sec-title3 mb-40">
                  <h2
                    className="title white-color mb-16 Heading"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1800"
                    style={{ fontSize: "40px", paddingTop: "120px" }}
                  >
                     {slice5?slice5?.body[0].primary.tittle[0].text:"Human Resource Management System"}
                  </h2>
                  <p
                    className="desc white-color  md-pr-0 sub-text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="3000"
                    style={{
                      fontSize: "15px",
                      lineHeight: " 1.4",
                      textAlign: "justify",
                    }}
                  >
                   {slice5?slice5?.body[0].primary.text1[0].text:" A Human Resource Management System or HRIS (Human Resource                    Information System) is a form of HR software that combines a                    number of systems and processes to ensure the easy                    management of a business's employees and data. Human                    Resources Software is used by businesses to combine a number                    of necessary HR functions, such as storing employee data,                    managing payrolls, recruitment processes, benefits                    administration and keeping track of attendance records."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-ss main-home event-bg pt-50 pb-10 md-pt-30 md-pb-0"
        style={{ background: "#4f82c2" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-right"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[0].cardimages.url:img1}
                title={slice5?slice5?.body[1].items[0].tittle[0].text:"Time And Attendance Module"}
                subtitle={slice5?slice5?.body[1].items[0].subtittle[0].text:"The most advanced modules provide broad flexibility in data collection methods, labor distribution capabilities and data analysis features."}
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[1].cardimages.url:img2}
                title={slice5?slice5?.body[1].items[1].tittle[0].text:"Benefits Administration Module"}
                subtitle={slice5?slice5?.body[1].items[1].subtittle[0].text:"The benefits administration module provides a system for organizations to administer and track employee participation in benefits programs."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="zoom-out-left"
              data-aos-duration="1500"
            >
              <CardSingle
                itemClass="courses-item"
                image={slice5?slice5?.body[1].items[2].cardimages.url:img3}
                title={slice5?slice5?.body[1].items[2].tittle[0].text:"HR management module"}
                subtitle={slice5?slice5?.body[1].items[2].subtittle[0].text:"Is the strategic approach to the effective management of organization workers so that they help the business gain a competitive advantage, Commonly known as the HR Department, it is designed to maximize employee performance in service of an employer's strategic objectives."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-Text main-home event-bg pt-10 pb-40 md-pt-10 md-pb-20"
        style={Textbg}
      >
        <div className="container">
          <div className="row">
            <h3 style={{ color: "white" }}>{slice5?slice5?.body[2].primary.tittle[0].text:"Key Elements of HRM"}</h3>

            <div className="col-lg-4 col-md-6 mb-30"
            data-aos="fade-left"
              data-aos-duration="1500">
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[0].tittle[0].text:"Organization"}
                subtitle={slice5?slice5?.body[2].items[0].subtittle[0].text:"The Organization module is organization structure such as company, location, department, designations, employee group and organization change such as resignation, termination, transfer, promotion."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
             
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[1].tittle[0].text:"Employee Self-Service"}
                subtitle={slice5?slice5?.body[2].items[1].subtittle[0].text:"The Employee Self-Service module allows employees to query HR related data and perform some HR transactions over the system."}
              />
            </div>
            <div
              className="col-lg-4 col-md-6 mb-30"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <TextSingle
                itemClass="courses-item"
                title={slice5?slice5?.body[2].items[2].tittle[0].text:"Reports"}
                subtitle={slice5?slice5?.body[2].items[2].subtittle[0].text:"The Reports Module provides customized reporting according to employees individual needs.Report definitions can be saved to avoid repeating this task."}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="noraml-Text main-home event-bg pt-30 pb-40 md-pt-10 md-pb-110"
        style={NormalTextbg}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-11 col-md-6 mb-100"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <NormalText
                itemClass="courses-item"
                title={slice5?slice5?.body[3].primary.tittle[0].text:"Functions"}
                listClass="display-none"
                portalList="portal-none"
                cloudList="cloud-none"
                ecommerce="ecommerce-none"
                listtwo="listtwo-none"
                listthree="listthree-none"
                subtitle1={slice5?slice5?.body[3].primary.text1[0].text:"The function of human resources (HR) departments is administrative and common to all organizations. Organizations may have formalized selection, evaluation, and payroll processes. Management of human capital progressed to an imperative and complex process."}
                subtitle2={slice5?slice5?.body[3].primary.text2[0].text:"HR executives rely on internal or external IT professionals to develop and maintain an integrated HRMS."}
               
              />
            </div>
          </div>
        </div>
      </div>
      <LifeSpan />
    </React.Fragment>
  );
};

export default Hrms;
