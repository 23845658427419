import React from "react";
import Slider from "react-slick";
import SingleTeam from "../../components/Team/SingleTeam";

// Team Members
import teamimg1 from "../../assets/img/team/1.jpg";
import teamimg2 from "../../assets/img/team/2.jpg";


const Team = (props:any) => {
  const{location}=props;
 
  const slilderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,

    className: "active",
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div
        id="rs-team"
        className="rs-team style1 nav-style2 pt-50 pb-1 md-pt-64 md-pb-70"
      >
        <div className="container">
          <Slider {...slilderSettings}>
            <SingleTeam
              itemClass="team-item"
              Image={location?location.body[1].items[0].images.url:teamimg1}
              Title={location?location.body[1].items[0].tittle[0].text:"India"}
              Designation={location?location.body[1].items[0].desc[0].text:"P-1, 4th Floor, Gold Signature, 95,Frazer Town, Above J&K Bank,Bangalore-560005.esquare@info.com"}
            />

            <SingleTeam
              teamClass="team-item"
              Image={location?location.body[1].items[1].images.url:teamimg2}
              Title={location?location.body[1].items[1].tittle[0].text:"United kingdom"}
              Designation={location?location.body[1].items[1].desc[0].text:" #377 Green Ln, Birmingham B9 5PP, United Kingdom. esquare@info.uk"}
            />

            {/* <SingleTeam
              teamClass="team-item"
              Image={location?location.body[1].items[2].images.url:teamimg3}
              Title={location?location.body[1].items[2].tittle[0].text:"Dubai"}
              Designation={location?location.body[1].items[2].desc[0].text:"GräfelfingbeiMünchen        C/O-Co.n.t.r.a- 3000              Postfach1363, 82155               GräfelfingbeiMünchen               sales@esquareinfo.com"}
            /> */}
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Team;
